import React, { forwardRef, memo, useMemo, useState, useCallback, } from 'react'
import reactFastCompare from 'react-fast-compare'
import './password.scss'
import passwordValidations from './validations'
import regexDictionary from 'scripts/regexDictionary'
import { TextField, Grid, Text } from 'components'


const Password = forwardRef(function Password(props, ref) {
    /*------------------------------------PROPS--------------------------------*/
    let {
        validate = false,
        onChange,
        passwordRef,
        ...rest
    } = props

    /*------------------------------------STATE--------------------------------*/
    const [errors, setErrors] = useState(Object.keys(passwordValidations).map(() => true));
    const [emptyValue, setEmptyValue] = useState(true);

    const validatePassword = useCallback((value) => {
        if (!validate) return

        let newPasswordErrors = [false, false, false]
        Object.keys(passwordValidations).forEach((key) => {
            if (passwordValidations[key].validate(value))
                newPasswordErrors[key - 1] = true
        })
        setErrors(newPasswordErrors)
    }, [validate, setErrors])

    const renderLabels = useMemo(() => {
        return Object.keys(passwordValidations).map((key) => {
            return <Text color='second' medium display="inline" key={key} size='2' className={errors[key - 1] === true ? "error" : "success"}>{passwordValidations[key].text}</Text>
        })
    }, [errors])

    return (
        <Grid gap="0.5em" className="password__wrapper">
            <TextField
                type="password"
                onChange={(v) => { onChange(v); validatePassword(v); setEmptyValue(!v || v.length === 0) }}
                pattern={validate ? regexDictionary.passwordRegex.overAllRegex : null}
                ref={passwordRef}
                {...rest}
            />
            {(validate && !emptyValue) && <Grid gap="0.5em">{renderLabels}</Grid>}
        </Grid>
    )
})

export default memo(Password, reactFastCompare)
