import { fetchCompanyData } from 'redux/reducers/auth/authSlice'
import { getAppTheme } from 'redux/reducers/apptheme/appThemeSlice'
import { useDispatch } from 'react-redux'

const useFetchingGlobalData = () => {
  const dispatch = useDispatch()

  const fetchGlobalData = () => {
    dispatch(fetchCompanyData())
    dispatch(getAppTheme())
  }

  return fetchGlobalData
}

export default useFetchingGlobalData
