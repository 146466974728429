
import { createRef, useCallback, useRef } from 'react'

import useMessageBoxContext from './useMessageBoxContext'

import { Button, Flex } from 'components'
import useValidateFields from './useValidateFields'

const useRecordGroup = (props) => {
    /*--------------------------------------PROPS-----------------------------------*/
    const {
        recordGroupRef, fields, formData, setFormData, value, onChange,
        identifier, onValidateForm
    } = props

    /*--------------------------------------REFS-----------------------------------*/
    const fieldsRef = useRef(Object.keys(fields).map(() => createRef()))

    /*--------------------------------------HOOKS-----------------------------------*/
    const { hideMB, showMB, showMBError } = useMessageBoxContext()
    const validateFields = useValidateFields()

    /*--------------------------------------FUNCTIONS-----------------------------------*/
    const handleChangeForm = useCallback((value, name) => {
        setFormData(formData => ({ ...formData, [name]: value }))
    }, [setFormData])

    const handleChangeRecord = useCallback((record, id) => {
        setFormData({ id, ...fields, ...record })
    }, [fields, setFormData])

    const validateNewRecord = useCallback(record => {
        if (identifier) {
            const [fieldName, identifierText] = identifier
            for (const key in value) {
                if (key !== record.id && value[key][fieldName] === record[fieldName])
                    return showMB({
                        content: typeof identifierText === 'function' ?
                            identifierText(record[fieldName])
                            :
                            `El ${identifierText} "${record[fieldName]}" ya esta registrado.`,
                        buttons: <Button onClick={hideMB}>Entendido</Button>
                    })
            }
        }

        return true
    }, [hideMB, identifier, showMB, value])

    const handleSave = useCallback(record => {
        if (!validateNewRecord(record)) return

        record._actionType = record.id < 0 ? 'create' : 'update'
        onChange({ ...value, [record.id]: record }, record)
        recordGroupRef.current.closeForm()
    }, [value, onChange, recordGroupRef, validateNewRecord])

    const handleClickSave = useCallback(() => {
        const { valid, errors } = validateFields(fieldsRef)
        if (!valid) return showMBError(errors)
        if (onValidateForm && !onValidateForm(formData, fieldsRef.current)) return

        handleSave(formData)
    }, [formData, handleSave, onValidateForm, showMBError, validateFields])

    /*--------------------------------------EXTERNAL FUNCTIONS-----------------------------------*/

    /*--------------------------------------RETURN-----------------------------------*/

    const FormButtons = ({
        onClickCancel = recordGroupRef.current?.closeForm,
        onClickSave = handleClickSave
    }) => (
        <Flex wrap align='center' justify='center'>
            <Button onClick={onClickCancel} variant="outlined" style={{ margin: '0.5em' }}>Cancelar</Button>
            <Button onClick={onClickSave} style={{ margin: '0.5em' }}>Guardar</Button>
        </Flex>
    )

    return { fieldsRef, FormButtons, handleChangeForm, handleChangeRecord, onSave: handleSave }

}

export default useRecordGroup