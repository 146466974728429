import { createSlice } from '@reduxjs/toolkit'
import { isAdminSubdomain } from 'scripts/generalVariables'

const initialState = {
    // eslint-disable-next-line
    isAdminMode: isAdminSubdomain,
    isIdle: false,
}

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setAppOnIdle: (state, { payload }) => {
            state.isIdle = payload
            localStorage.setItem('isIdle', payload)
            document.querySelector('.app_container')?.classList.toggle('is_idle', payload)
        }
    }
})

export const { setAppOnIdle } = appSlice.actions

export default appSlice.reducer