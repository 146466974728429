import React, { useCallback } from 'react'
// import reactFastCompare from 'react-fast-compare'

import { Grid, Text, IconButton } from 'components'

import ListItem from './RecordGroupListItem'
import HelpButton from 'components/modal/HelpButton/HelpButton'

const RecordGroupList = props => {
    const { value, columns, templateColumns, handleDelete, openForm, hideAddBtn } = props

    const handleClickAdd = useCallback(() => {
        openForm()
    }, [openForm])

    return (
        <Grid className='record_group__list' columns='100%'>
            <Grid className='record_group__header' itemsY='center' direction='column' columns={templateColumns} pad='0.3em 1em'>
                {columns.map((column, i) =>
                    <Grid key={i} columns={`auto${column.helpText ? ' 1fr' : ''}`} gap="0.5em">
                        <Text className="record_group__header__text" size="2">{column.label}</Text>
                        {column.helpText && <HelpButton>{column.helpText}</HelpButton>}
                    </Grid>
                )}
                <IconButton style={{ visibility: hideAddBtn ? 'hidden' : '' }} icon='add' size='1.25' onClick={handleClickAdd} />
            </Grid>
            <Grid className="record_group__content">
                {global.mapObjectHelper(value, (record, key) =>
                    <ListItem {...{
                        key,
                        templateColumns,
                        columns,
                        record,
                        openForm: () => openForm({ ...record, id: key }),
                        handleDelete,
                    }} />
                )}
            </Grid>
        </Grid>
    )
}

export default RecordGroupList