import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import MessageBox from '../MessageBox/MessageBox'
import { Button } from 'components'
import { mdAnimations } from 'scripts/generalVariables'

const MessageConfirm = (props, ref) => {

    /*--------------------------------------REFS-------------------------------------------*/
    const messageBoxRef = useRef()

    /*--------------------------------------FUNCTIONS-------------------------------------------*/
    const hide = useCallback(() => {
        messageBoxRef.current.hide()
    }, [])

    const show = useCallback(({
        title = 'La acción requiere confirmación',
        content = '¿Deseas continuar?',
        animation = mdAnimations.INFO,
        confirmText = 'Continuar', cancelText = 'Cancelar',
        confirm = () => { }, cancel = () => { } } = {},
    ) => {
        messageBoxRef.current.show({
            title,
            content,
            animation,
            buttons: (
                <>
                    <Button variant='outlined' onClick={() => { cancel(); hide() }}>{cancelText}</Button>
                    <Button onClick={() => { confirm(); hide() }}>{confirmText}</Button>
                </>
            )
        })

    }, [hide])

    /*--------------------------------------EXTERNAL FUNCTIONS-------------------------------------------*/
    useImperativeHandle(ref, () => ({
        hide,
        show,
    }), [hide, show])

    /*--------------------------------------RENDER-------------------------------------------*/
    return (
        <MessageBox ref={messageBoxRef} />
    )
}

export default forwardRef(MessageConfirm)