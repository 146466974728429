import React from 'react'
import { Navigate, Route, BrowserRouter, Routes, Outlet } from 'react-router-dom'
import { adminRoutes, publicRoutes, requireAuthAdminRoutes, requireAuthRoutes, requireNoAuthAdminRoutes, requireNoAuthRoutes, routes } from 'routing/routes'
import { Provider as ReduxProvider, useSelector } from 'react-redux'
import store from 'redux/store'

import { MessageBoxContextProvider } from 'context/MessageDialogContext'
import { AppProcessingContextProvider } from 'context/AppProcessingContext'
import { AppSpinnerContextProvider } from 'context/AppSpinnerContext'
import { SideMenuContextProvider } from 'context/SideMenuContext'
import { ProfileMenuContextProvider } from 'context/ProfileMenuContext'

import { useComposeProviders } from 'hooks'

import RequireAuth from 'routing/RequireAuth'
import RequireNoAuth from 'routing/RequireNoAuth'
import AxiosProvider from 'config/AxiosProvider'
import IdleAppProvider from 'config/IdleAppProvider'

import Layout from 'Layout/Layout'
import AdminLayout from 'Layout/Admin/AdminLayout'

function AppRouter() {
  const { app } = useSelector(store => store)
  const RouterProviders = useComposeProviders(BrowserRouter, Routes)
  const AuthRoutesProviders = useComposeProviders(IdleAppProvider, RequireAuth)

  return (
    <RouterProviders>
      <Route element={<AxiosProvider />}>
        {app.isAdminMode ?
          <>
            <Route path="" element={<Outlet />}>
              {
                publicRoutes.map(routeName =>
                  <Route key={routeName} path={routes[routeName].path} element={routes[routeName].element} />
                )
              }
              <Route element={<AuthRoutesProviders />}>
                <Route element={<AdminLayout />}>
                  {
                    requireAuthAdminRoutes.map(routeName =>
                      <Route key={routeName} path={adminRoutes[routeName].path} element={adminRoutes[routeName].element} />
                    )
                  }
                </Route>
              </Route >
              <Route element={<RequireNoAuth />}>
                {requireNoAuthAdminRoutes.map(routeName =>
                  <Route key={routeName} path={adminRoutes[routeName].path} element={adminRoutes[routeName].element} />
                )}
              </Route>
            </Route>
            <Route path="*" element={<Navigate to='/' replace />} />
          </>
          :
          <>
            <Route path="" element={<Layout />}>
              {
                publicRoutes.map(routeName =>
                  <Route key={routeName} path={routes[routeName].path} element={routes[routeName].element} />
                )
              }
              <Route element={<AuthRoutesProviders />}>
                {
                  requireAuthRoutes.map(routeName =>
                    <Route key={routeName} path={routes[routeName].path} element={routes[routeName].element} />
                  )
                }
              </Route >
              <Route element={<RequireNoAuth />}>
                {requireNoAuthRoutes.map(routeName =>
                  <Route key={routeName} path={routes[routeName].path} element={routes[routeName].element} />
                )}
              </Route>
            </Route >
            <Route path="*" element={<Navigate to='' replace />} />
          </>
        }
      </Route>
    </RouterProviders >
  )
}

function App() {

  const AppProviders = useComposeProviders(
    MessageBoxContextProvider,
    AppProcessingContextProvider,
    AppSpinnerContextProvider,
    SideMenuContextProvider,
    ProfileMenuContextProvider
  )

  return (
    <ReduxProvider store={store}>
      <AppProviders>
        <AppRouter />
      </AppProviders>
    </ReduxProvider>
  )
}

export default App
