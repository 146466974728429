import React, { useState } from 'react'
import axios from 'axios'

import { Button, Grid, Modal, Text, TextField } from 'components'

const TokenEditModal = (props) => {
    /*------------------------------------PROPS--------------------------------*/
    const { closeModal, moduleId, tokens: moduleTokens, setModuleData } = props

    /*------------------------------------STATE--------------------------------*/
    const [tokens, setTokens] = useState(moduleTokens)

    /*------------------------------------FUNCTIONS--------------------------------*/
    const addTokens = async () => {
        try {
            const addTokens = Math.trunc(tokens)
            const response = await axios.post(`/admin/tokens/add/${moduleId}`, { tokens: addTokens }, { appSpinner: true })
            if (response.data.success) {
                closeModal()
                setModuleData(moduleData => ({ ...moduleData, tokens: addTokens }))
            }
        } catch (error) {
            console.error('ADD_TOKENS_ERROR')
        }
    }

    return (
        <Modal maxWidth='25rem'>
            <Grid gap='2rem'>
                <Grid gap='1rem' itemsX='center'>
                    <Text align='center' size={5}>Editar tokens</Text>
                    <TextField type='number' label='Tokens disponibles' value={tokens} onChange={setTokens} />
                </Grid>
                <Grid gap='1rem' direction='column' contentX='center'>
                    <Button variant='outlined' onClick={closeModal}>Cancelar</Button>
                    <Button onClick={addTokens}>Guardar</Button>
                </Grid>
            </Grid>
        </Modal>
    )
}

export default TokenEditModal
