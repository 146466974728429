import React from 'react'

import { Grid, TextField } from 'components'
import regexDictionary from 'scripts/regexDictionary'

import './color_picker.scss'

const ColorPicker = (props) => {
    const {
        value,
        onChange,
        colorRef,
        onBlur,
    } = props

    return (
        <Grid itemsY='end' direction='column' gap='0.71rem'>
            <input type='color' value={value} onChange={e => onChange(e.target.value)} />
            <TextField onBlur={onBlur} ref={colorRef} pattern={regexDictionary.hexColor} maxLength="7" maxWidth="7.85rem" label='HEX' value={value} onChange={onChange} />
        </Grid>
    )
}

export default ColorPicker