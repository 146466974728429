import React, { Fragment, useState } from 'react'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'

import { Button, ComboBox, Flex, Grid, Text } from 'components'

import { planOptions } from '../../fieldsOptions'

import EditBalance from './EditBalance/EditBalance'
import RechargeBalance from './RechargeBalance/RechargeBalance'

const defaultModule = { id: 0, moduleType: 'MODULE', name: '', plan: '', fee: 0, state: '', expirationDate: '', balance: 0 }

const CompanyModuleInfo = () => {
    /*------------------------------------STATE--------------------------------*/
    const location = useLocation()
    const { moduleData: locationStateModuleData } = location.state || defaultModule

    const [moduleData, setModuleData] = useState(locationStateModuleData)
    const [openEditBalance, setOpenEditBalance] = useState(false)
    const [openRechargeBalance, setOpenRechargeBalance] = useState(false)

    /*------------------------------------HOOKS--------------------------------*/
    const navigate = useNavigate()

    /*------------------------------------FUNCTIONS--------------------------------*/
    const handleChangePlan = async (value) => {
        try {
            const response = await axios.put(`/admin/modules/${moduleData.id}/plans`, { planId: value }, { appSpinner: true })
            if (response.data.success) {
                setModuleData(moduleData => ({ ...moduleData, plan: value }))
            }
        } catch (error) {
            console.error('CHANGE_PLAN_ERROR', error)
        }
    }

    const toggleEditBalanceModal = () => {
        setOpenEditBalance(openEditBalance => !openEditBalance)
    }

    const toggleRechargeBalanceModal = () => {
        setOpenRechargeBalance(openRechargeBalance => !openRechargeBalance)
    }

    /*------------------------------------EFFECT--------------------------------*/

    return (
        <Fragment>
            <Button alignSelf='start' onClick={() => navigate(-1)}>Regresar</Button>
            <Flex direction='column' className='company_info__module_info_page' align='center'>
                <Text bold size={7} align='center'>{moduleData.name}</Text>
                <Grid gap='2rem' w100 maxWidth='25rem' padding='1rem'>
                    <Grid gap='1rem'>
                        <ComboBox label='Plan' options={planOptions} value={moduleData.plan} onChange={handleChangePlan} />
                    </Grid>
                    <Grid gap='1rem'>
                        <Text align='center'>Saldo</Text>
                        <Text bold align='center' size={7}>{global.parseCurrency(moduleData.balance).replace('$', '')}</Text>
                    </Grid>
                    <Grid gap='1rem' columns='repeat(2, auto)' contentX='center' itemsX='center'>
                        <Button justifySelf='center' onClick={toggleEditBalanceModal}>Editar saldo</Button>
                        {moduleData.name === "Psicología \nIndustrial" &&
                            <Button onClick={toggleRechargeBalanceModal}>Recarga</Button>
                        }
                    </Grid>
                </Grid>
            </Flex>
            {openEditBalance &&
                <EditBalance
                    moduleId={moduleData.id}
                    setModuleData={setModuleData}
                    toggleEditBalanceModal={toggleEditBalanceModal}
                />
            }
            {openRechargeBalance &&
                < RechargeBalance
                    closeModal={toggleRechargeBalanceModal}
                    moduleId={moduleData.id}
                />
            }
        </Fragment>
    )
}

export default CompanyModuleInfo
