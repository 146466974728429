import React, { useState } from 'react'
import axios from 'axios'

import { Button, Grid, Modal, Text, TextField } from 'components'

const EditBalance = (props) => {
    /*------------------------------------PROPS--------------------------------*/
    const { moduleId, setModuleData, toggleEditBalanceModal } = props

    /*------------------------------------STATE--------------------------------*/
    const [balance, setBalance] = useState()

    /*------------------------------------FUNCTION--------------------------------*/
    const saveNewBalance = async () => {
        try {
            const newBalance = Number(balance).toFixed(2)
            const response = await axios.put(`/admin/modules/${moduleId}/balance`, { balance: newBalance }, { appSpinner: true })
            if (response.data.success) {
                toggleEditBalanceModal()
                setModuleData(moduleData => ({ ...moduleData, balance: newBalance }))
            }
        } catch (error) {
            console.error('SAVE_NEW_BALANCE_ERROR')
        }
    }

    return (
        <Modal className='module_info__edit_balance' maxWidth='25rem'>
            <Grid gap='2rem'>
                <Text bold align='center' size='5'>EDITAR SALDO</Text>
                <TextField step='0.01' type='number' label='Nuevo saldo' value={balance} onChange={setBalance} />
                <Grid direction='column' gap='1rem' itemsX='center'>
                    <Button variant='outlined' onClick={toggleEditBalanceModal}>Cancelar</Button>
                    <Button onClick={saveNewBalance}>Guardar</Button>
                </Grid>
            </Grid>
        </Modal>
    )
}

export default EditBalance
