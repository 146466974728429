import { createContext, useCallback, useRef, useState } from "react"
import { useClickOutside } from 'hooks'


const SideMenuContext = createContext({isOpen: false})

export const SideMenuContextProvider = ({children}) => {
    const [isOpen, setIsOpen] = useState(false)
    const burgerButton = useRef()
    const sideMenu = useRef()
    // const [sideMenuSection, setSideMenuSection] = useState(false)
    const toggleSideMenu = useCallback(() => {
        setIsOpen(isOpen => !isOpen)
    }, [])

    useClickOutside(sideMenu, (e) => {
        if(isOpen){
            if(burgerButton.current && !burgerButton.current.contains(e.target)){
                toggleSideMenu()
            }
        }
    })
    
    return <SideMenuContext.Provider {...{value: {isOpen, toggleSideMenu, burgerButton, sideMenu}, children}} />
}

export default SideMenuContext