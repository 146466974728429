import React, { memo } from 'react'

import './divisor.scss'

export default memo(function Divisor(props) {
    let {
        className = '',
        color = 'first',
        thickness = '1px',
        width = '',
        height = '',
        maxWidth = '',
        vertical = false,
        margin
    } = props

    let style = {}

    className += ` divisor divisor--color--${color}`

    if (vertical) {
        height = height || '100%'
        width = 0
        style["borderLeftWidth"] = thickness
    } else {
        height = 0
        width = width || '100%'
        style["borderTopWidth"] = thickness
    }

    style["width"] = width
    style["height"] = height
    style["maxWidth"] = maxWidth
    style["margin"] = margin

    return <div {...{ className, style }} />
})