import axios from "axios"

const signup = async (userData) => {
    const response = await axios.post('/company/create/account', userData, { appProcessing: true })

    if (response.data && !response.data.error) {
        global.setSession(response.data.user, response.data.token)
    } else {
        localStorage.removeItem('user')
        localStorage.removeItem('token')
    }

    return response.data
}

const login = async (userData) => {
    const response = await axios.post('/user/login', userData, { appProcessing: true })
    if (response.data && !response.data.error) {
        global.setSession(response.data.user, response.data.token)
    } else {
        localStorage.removeItem('user')
        localStorage.removeItem('token')
    }

    return response.data
}

const adminLogin = async (userData) => {
    const response = await axios.post('/admin/session/login', userData, { appProcessing: true })
    if (response.data && !response.data.error) {
        global.setSession(response.data.user, response.data.token)
    } else {
        localStorage.removeItem('user')
        localStorage.removeItem('token')
    }

    return response.data
}

const logout = async () => {
    const response = await axios.post('/user/logout', {}, { appProcessing: true })
    return response.data
}

const fetchCompanyData = async () => {
    const response = await axios.get('/company/account', {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
    }, { appProcessing: true })

    if (response.data && !response.data.error) {
        const { id, ...rest } = response.data
        localStorage.setItem('company', JSON.stringify(rest))
    } else
        localStorage.removeItem('company')
    return response.data
}

const authFunctions = { signup, login, adminLogin, logout, fetchCompanyData }

export default authFunctions