import React, { useCallback, useRef, useState } from 'react'
// import axios from 'axios';
import { useDispatch } from 'react-redux'

//import 'scripts/recaptcha'
import { Button, Grid, TextField, Text, Toggle, ComboBox, Link, Password } from 'components'

import { signup } from 'redux/reducers/auth/authSlice'


import './signup.scss'

const Signup = (props) => {
    var loadjs = require('loadjs');
    loadjs('https://www.google.com/recaptcha/api.js');
    const { handleCurrentScreen } = props
    const [termsConditions, setTermsConditions] = useState(false)
    const [formData, setFormData] = useState({ name: '', email: '', password: '', hasManyEmployees: '' })
    const [emailError/*, setEmailError*/] = useState('')
    const [error, setError] = useState('')
    const [showError, setShowError] = useState(false)
    const dispatch = useDispatch()

    const nameRef = useRef()
    const emailRef = useRef()
    const passwordRef = useRef()
    const employeesRef = useRef()

    const handleChange = useCallback((value, name) => {
        setFormData(formData => ({ ...formData, [name]: value }))
    }, [])

    const displayError = useCallback((message) => {
        setShowError(true)
        setError(message)
    }, [setError, setShowError])

    const handleSumbit = async e => {
        e.preventDefault()

        const formDataKeys = Object.keys(formData)
        const formDataReferences = { name: nameRef, email: emailRef, password: passwordRef, hasManyEmployees: employeesRef }
        const areThereEmptyValues = Object.values(formData).some(value => value === undefined || value ==='' )

        if(areThereEmptyValues){
            formDataKeys.forEach((key) => {
                if(formData[key] === ''){
                    formDataReferences[key]?.current.validate()
                    formDataReferences[key]?.current.setStatus('error')
                }
            })
            displayError('Hay campos vacios')
            return;
        }else
            setShowError(false)

        if (!termsConditions){
            displayError('Es necesario aceptar los terminos y condiciones')
            return;
        }

        if (!getCaptchaResponse()) {
            displayError('Es necesario completar el captcha.')
            return;
        }

        dispatch(signup(formData)).then(res => {
            // if (res.payload.error) return setError('El correo ya esta en uso, utiliza otro')
            if (res.error) 
                displayError('El correo ya esta en uso')
        }).catch(err => {
            console.log("ad", err)
        })
    }

    const getCaptchaResponse = () => {
        return window.grecaptcha.getResponse()
    }

    // const validateEmail = useCallback(async () => {
    //     try {
    //         const response = await axios.get(`/user/email/validate?email=${formData.email}`)
    //         if (response.data.valid) setEmailError('')
    //     } catch (error) {
    //         setEmailError(error.response.data?.errors?.[0] || 'El correo ya esta en uso. Elige otro.')
    //     }
    // }, [formData.email])


    return (
        <Grid className="signup" contentY="center" itemsX="center" gap="1.2rem">
            <Text style={{marginBottom: '5px'}} bold align="center" size="4">Abre tu cuenta</Text>
            <Text medium align="center" className="text_already_account">¿Ya tienes cuenta en MaGiEi? <span onClick={() => { handleCurrentScreen(0) }} className='span_main_color_clickeable span_main_color_already_account'>Haz click aquí.</span></Text>

            <form onSubmit={handleSumbit} autoComplete='off'>
                <Grid w100 maxWidth="22.85em" className="signup__form" gap="1.72em">
                    <TextField label="Nombre o razón social"
                        value={formData.name}
                        onChange={v => handleChange(v, 'name')}
                        ref={nameRef}
                    />
                    <TextField label="Correo electrónico"
                        type="email"
                        value={formData.email}
                        onChange={v => handleChange(v, 'email')}
                        ref={emailRef}
                    />
                    {emailError && <Text color="error">{emailError}</Text>}
                    <Password label="Contraseña"
                        value={formData.password}
                        validate={true}
                        onChange={v => handleChange(v, 'password')}
                        passwordRef={passwordRef}
                    />
                    <ComboBox
                        label="Cantidad aprox. de trabajadores"
                        options={{ 0: 'Menos de 50 trabajadores', 1: 'Entre 20 y 200 trabajadores',2:'Entre 200 y 1000 trabajadores', 3:'Entre 1000 y 5000 trabajadores',4:'Mas de 5000 trabajadores'}}
                        value={formData.hasManyEmployees}
                        sortList={false}
                        onChange={v => handleChange(parseInt(v), 'hasManyEmployees')}
                        ref={employeesRef}
                    />
                    
                    <div className="g-recaptcha" data-sitekey='6LdfYOElAAAAAJrtQTBZC4Gs9uxlVhiCmUF38XY7'></div>
                    
                    <Grid columns="auto 1fr" gap="0.5em" itemsY="center">

                        <Toggle value={termsConditions} onChange={() => {setTermsConditions(current => !current); setShowError(false)}} />
                        <Text medium >He leído y acepto los  <Link  className="span_main_color_clickeable"  bold href="https://magiei.com/privacidad/?id=5" size='3' target="_blank" >Términos y condiciones</Link> y el 
                        <Link  className="span_main_color_clickeable"  bold href="https://magiei.com/privacidad/" size='3'  target="_blank"  > Aviso de privacidad</Link> 
                        </Text>

                    </Grid>

                    {showError && <Text color="error" align="center">{error}</Text>}
                    <Button type="submit" selfCenter>Abrir cuenta</Button>
                </Grid>
            </form>
        </Grid>
    )
}

export default Signup
