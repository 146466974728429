import { Button, Grid, RecordGroupCustom, Slider, TextField, Toggle } from 'components'
import { useMessageBoxContext } from 'hooks'
import useRecordGroup from 'hooks/useRecordGroup'
import React, { useCallback, useRef, useState } from 'react'

const emailsFields = {
    email: '',
    userName: '',
    domainName: ''
}

const UserEmailsForm = (props) => {
    const {
        emailsData,
        handleChangeForm,
        fieldsRef,
        recordGroupRef,
        RGFormButtons,
        value,
        onChange
    } = props

    const [uploadType, setUploadType] = useState(false)
    const [sliderScreen, setSliderScreen] = useState(0)
    const [jsonRecords, setJsonRecords] = useState('')

    const { showMBError } = useMessageBoxContext()

    const handleChangeUploadType = useCallback((value) => {
        setUploadType(value)
        setSliderScreen(value ? 1 : 0)
    }, [])

    const handleClickSaveJSON = useCallback(() => {
        let lastId = global.isEmptyObject(value) ? 0 : Math.min(...Object.keys(value))
        try {
            const emailsArray = JSON.parse(jsonRecords)
            const massEmails = {}

            if (emailsArray.constructor !== Array) throw new Error('El formato json es incorrecto')

            emailsArray.forEach((email) => {
                massEmails[--lastId] = { id: lastId, ...email }
            })

            onChange({ ...value, ...massEmails })
            handleChangeUploadType(0)
            recordGroupRef.current?.closeForm()
        } catch (error) {
            showMBError(error.message)
        }
    }, [handleChangeUploadType, jsonRecords, onChange, recordGroupRef, showMBError, value])

    return (
        <Grid itemsX='center' gap='1rem'>
            {emailsData.isNewRecord &&
                < Toggle label1='Individual' label2='En masa' value={uploadType} onChange={handleChangeUploadType} />
            }
            <Slider currentScreen={sliderScreen}>
                <Grid gap='0.5rem'>
                    <TextField required ref={fieldsRef.current[0]} label='Correo' errorMessage='El campo "Correo" es requerido' type='email' value={emailsData.email} onChange={v => handleChangeForm(v, 'email')} />
                    <TextField required ref={fieldsRef.current[1]} label='Usuario' errorMessage='El campo "Usuario" es requerido' value={emailsData.userName} onChange={v => handleChangeForm(v, 'userName')} />
                    <TextField required ref={fieldsRef.current[2]} label='Dominio' errorMessage='El campo "Dominio" es requerido' value={emailsData.domainName} onChange={v => handleChangeForm(v, 'domainName')} />
                </Grid>
                <Grid>
                    <TextField value={jsonRecords} onChange={setJsonRecords} />
                </Grid>
            </Slider>
            <RGFormButtons onClickSave={uploadType ? handleClickSaveJSON : undefined} />
        </Grid>
    )
}

const UserEmails = (props) => {
    const {
        emailsRef,
        value,
        onChange
    } = props

    /*--------------------------------------STATE-----------------------------------*/
    const [emailsData, setEmailsData] = useState(emailsFields)

    /*--------------------------------------FUNCTIONS-----------------------------------*/


    /*--------------------------------------RECORD_GROUP HOOK-----------------------------------*/
    const { fieldsRef, FormButtons: RGFormButtons, handleChangeForm, handleChangeRecord } = useRecordGroup({
        recordGroupRef: emailsRef,
        fields: emailsFields,
        formData: emailsData,
        setFormData: setEmailsData,
        value: value,
        onChange: (v) => onChange(v, 'emails'),
        identifier: ['email', 'Correo']
    })

    /*--------------------------------------RENDER-----------------------------------*/
    return (
        <RecordGroupCustom
            ref={emailsRef}
            columns={[{ name: 'email', label: 'Correos' }]}
            value={value}
            onChange={v => onChange(v, 'emails')}
            onChangeRecord={handleChangeRecord}
            min={2}
            max={4}
            deletingMessage='¿Estás seguro que quieres borrar este registro?'
            errorMessage='El record group personalizado no es valido'
            formContent={
                <UserEmailsForm
                    emailsData={emailsData}
                    handleChangeForm={handleChangeForm}
                    fieldsRef={fieldsRef}
                    recordGroupRef={emailsRef}
                    RGFormButtons={RGFormButtons}
                    value={value}
                    onChange={(v) => onChange(v, 'emails')}
                />
            }
        />
    )
}

const TestComponent = () => {
    /*--------------------------------------PROPS-----------------------------------*/

    /*--------------------------------------STATE-----------------------------------*/
    const [formData, setFormData] = useState({ name: '', lastName: '', emails: {} })

    /*--------------------------------------REFS-----------------------------------*/
    const nameRef = useRef()
    const emailsRef = useRef()

    /*--------------------------------------HOOKS-----------------------------------*/

    /*--------------------------------------FUNCTIONS-----------------------------------*/
    const handleChangeFormData = useCallback((value, name) => {
        setFormData(formData => ({ ...formData, [name]: value }))
    }, [])

    /*--------------------------------------RECORD_GROUP HOOK-----------------------------------*/
    const handleSaveForm = () => {
        if (emailsRef.current.validate())
            console.log('Save ', formData)
    }

    /*--------------------------------------EFFECTS-----------------------------------*/

    return (
        <Grid gap='0.5rem'>
            <TextField ref={nameRef} label='Nombre' value={formData.name} onChange={v => handleChangeFormData(v, 'name')} />
            <TextField label='Apellido' value={formData.lastName} onChange={v => handleChangeFormData(v, 'lastName')} />
            <UserEmails emailsRef={emailsRef} value={formData.emails} onChange={v => handleChangeFormData(v, 'emails')} />
            <Button onClick={handleSaveForm}>Guardar</Button>
        </Grid>
    )
}

export default TestComponent