import React, { useState } from 'react'
import axios from 'axios'

import { Button, CheckBox, ComboBox, Grid, Modal, Text } from 'components'


const dayOptions = { 1: '1', 2: '2', 3: '3', 4: '4', 5: '5', 6: '6', 7: '7', 8: '8', 9: '9', 10: '10', 11: '11', 12: '12', 13: '13', 14: '14', 15: '15', 16: '16', 17: '17', 18: '18', 19: '19', 20: '20', 21: '21', 22: '22', 23: '23', 24: '24', 25: '25', 26: '26', 27: '27', 28: '28', 29: '29', 30: '30', 31: '31' }
const monthOptions = { 1: 'Enero', 2: 'Febrero', 3: 'Marzo', 4: 'Abril', 5: 'Mayo', 6: 'Junio', 7: 'Julio', 8: 'Agosto', 9: 'Septiembre', 10: 'Octubre', 11: 'Noviembre', 12: 'Diciembre' }
const yearOptions = { 2015: '2015', 2016: '2016', 2017: '2017', 2018: '2018', 2019: '2019', 2020: '2020', 2021: '2021', 2022: '2022', 2023: '2023', 2024: '2024', 2025: '2025', 2026: '2026' }

// Enviar fecha como año mes dia

const defaultDate = { day: '', month: '', year: '' }

const PayInvoiceModal = (props) => {
    /*------------------------------------PROPS--------------------------------*/
    const { invoice, setInvoices, toggleOpenPayInvoiceModal } = props

    /*------------------------------------STATE--------------------------------*/
    const [date, setDate] = useState(defaultDate)
    const [addBalance, setAddBalance] = useState(false)

    /*------------------------------------FUNCTIONS--------------------------------*/
    const handleChangeDate = (value, name) => {
        setDate(date => ({ ...date, [name]: value }))
    }

    const payInvoice = async () => {
        try {
            const paymentDate = `${date.year}-${date.month.toString().padStart(2, '0')}-${date.day.toString().padStart(2, '0')}`
            const endpoint = invoice.isTokenInvoice ?
                `/admin/tokens/create/${invoice.id}/payment-invoice`
                :
                `/admin/companies/modules/${invoice.id}/payment-invoice`

            const response = await axios.post(endpoint, { paymentDate, [invoice.isTokenInvoice ? 'addTokens' : 'addBalance']: addBalance }, { appSpinner: true })
            if (response.data) {
                setInvoices(invoices => {
                    const invoicesResult = [...invoices]
                    const invoiceIdx = invoicesResult.findIndex(e => e.id === invoice.id)
                    invoicesResult[invoiceIdx].status = 'PAID'
                    invoicesResult[invoiceIdx].date = paymentDate
                    return invoicesResult
                })
                toggleOpenPayInvoiceModal()
            }
        } catch (error) {
            console.error('PAY_INVOICE_ERROR')
        }
    }

    return (
        <Modal maxWidth='35rem'>
            <Grid gap='1rem'>
                <Text>Fecha de pago</Text>
                <Grid columns='1fr 2fr 2fr' gap='1rem' contentX='center'>
                    <ComboBox sortList={false} label='Dia' placeholder='Dia' options={dayOptions} value={date.day} onChange={v => { handleChangeDate(v, 'day') }} />
                    <ComboBox sortList={false} label='Mes' placeholder='Mes' options={monthOptions} value={date.month} onChange={v => { handleChangeDate(v, 'month') }} />
                    <ComboBox sortList={false} label='Año' placeholder='Año' options={yearOptions} value={date.year} onChange={v => { handleChangeDate(v, 'year') }} />
                </Grid>
                <Grid gap='1rem' direction='column' contentX='start' itemsY='center'>
                    <CheckBox value={addBalance} onChange={setAddBalance} />
                    <Text>Agregar {invoice.isTokenInvoice ? 'tokens' : 'saldo'}</Text>
                </Grid>
                <Grid gap='1rem' direction='column' itemsX='center'>
                    <Button onClick={() => toggleOpenPayInvoiceModal()} variant='outlined'>Cancelar</Button>
                    <Button onClick={payInvoice}>Generar complemento y marcar como pagada</Button>
                </Grid>
            </Grid>
        </Modal>
    )
}

export default PayInvoiceModal
