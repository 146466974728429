import React, { memo, useState, useCallback, useRef, useEffect } from 'react'
import { useClickOutside, useScrollOutside } from 'hooks'

import { Text, Grid, IconButton, Button } from 'components'
import { getRelativePosition } from 'scripts/elementPosition'
import { screenSizes } from 'scripts/generalVariables'

import './help_button.scss'

const HelpButton = (props, ref) => {
    
    let {
        children,
        helpBubbleClassName = '',
        helpBubbleMaxWidth = '',
        size = '1.25',
        position = 'bottom',
        hoverMode = false
    } = props
    
    /*-----------------------------------STATE---------------------------------*/
        const [isOpen, setIsOpen] = useState(false)
        const [positionResult, setPositionResult] = useState({})
        const helpBubbleRef = useRef(), helpButtonRef = useRef()
        
    /*----------------------------------FUNCTIONS----------------------------------*/
        useScrollOutside(helpBubbleRef, () => { handleClose() })
        
    /*----------------------------------FUNCTIONS----------------------------------*/
        const handleOpen = useCallback(() => { setIsOpen(open => !open) }, [])
        const handleClose = useCallback(() => { setIsOpen(false) }, [])
    
    /*-----------------------------------EFFECTS---------------------------------*/
        useEffect(() => {
            if(isOpen){
                const pos = getRelativePosition(position, helpButtonRef.current, helpBubbleRef.current)
                setPositionResult(pos)
            }
        }, [isOpen, position])
        
        useClickOutside(helpButtonRef, (e) => {
            handleClose()
        }, [isOpen])
    
    /*---------------------------------POSITIONING---------------------------------*/
        let style = {}
        if(!isOpen) style['display'] = 'none'
        style['top'] = positionResult['top']
        style['bottom'] = positionResult['bottom']
        style['left'] = positionResult['left']
        style['right'] = positionResult['right']
        if(window.innerWidth <= screenSizes.laptop) {
            style['left'] = '0px'
            style['right'] = '0px'
        }
        
    /*------------------------------------RENDER-----------------------------------*/
        const content = React.Children.map(children, child => {
            if(typeof(child) == 'string') {
                return <Text className="help_button__text" align='center' style={{whiteSpace: "pre-line"}}>{child}</Text>
            }
            return child
        })
    
    return(
        <Grid className="help_button_container" onMouseEnter={hoverMode ? handleOpen : null}  onMouseLeave={hoverMode ? handleClose : null } ref={helpButtonRef}>
            <IconButton className="help_button" size={size} icon="help" onClick={hoverMode ? null : handleOpen} filter='active'/>
            <Grid ref={helpBubbleRef} rows={`${hoverMode ? '' : '1fr auto'}`} className={`${helpBubbleClassName} help_bubble help_bubble--position--${position}`}
                maxWidth={helpBubbleMaxWidth} style={style} gap="1.5em">
                <Grid columns="100%" gap="0.5em">
                    {content}
                </Grid>
                {!hoverMode &&
                <Button onClick={handleClose}>Entendido</Button>
                }
            </Grid>
        </Grid>
    )
}


export default memo(HelpButton)