
const useValidateFields = () => (fieldsRef) => {
    const errors = []
    let valid = true

    fieldsRef.current.forEach(fieldRef => {
        if (fieldRef.current?.validate && !fieldRef.current.validate()) {
            valid = false
            if (fieldRef.current.errorMessage !== '')
                errors.push(fieldRef.current.errorMessage)
        }
    })

    return { valid, errors }
}

export default useValidateFields