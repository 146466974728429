import React, { useState } from 'react'
import axios from 'axios'

import { Button, Grid, Modal, Text, TextField } from 'components'

const TokenSellingModal = (props) => {
    /*------------------------------------PROPS--------------------------------*/
    const { closeModal, moduleId } = props

    /*------------------------------------STATE--------------------------------*/
    const [balance, setBalance] = useState(0)
    const [price, setPrice] = useState(0)

    /*------------------------------------FUNCTIONS--------------------------------*/
    const saveTokenSelling = async () => {
        try {
            const response = await axios.post(`/admin/tokens/create/${moduleId}/income-invoice`,
                { tokens: balance, price },
                { appSpinner: true, catchErrors: true }
            )
            if (response.data) {
                closeModal()
            }
        } catch (error) {
            console.error('SAVE_TOKEN_SELLING_ERROR')
        }
    }

    return (
        <Modal maxWidth='25rem'>
            <Grid gap='2rem'>
                <Grid gap='1rem' itemsX='center'>
                    <Text align='center' size={5}>Venta de tokens</Text>
                    <TextField type='number' label='Cantidad de tokens' value={balance} onChange={setBalance} />
                    <TextField type='number' label='Precio por token' value={price} onChange={setPrice} />
                    <Text align='center'>Total a pagar</Text>
                    <Text bold align='center'>{global.parseCurrency(balance * price)}</Text>
                </Grid>
                <Grid gap='1rem' direction='column' contentX='center'>
                    <Button variant='outlined' onClick={closeModal}>Cancelar</Button>
                    <Button onClick={saveTokenSelling}>Guardar</Button>
                </Grid>
            </Grid>
        </Modal>
    )
}

export default TokenSellingModal
