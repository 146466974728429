import React from 'react'
import { Grid, Button} from 'components'
import './index.scss'

import { useBussinessProfileForm } from './hooks/useBussinessProfileForm';


export default function BusinessProfileForm(props) {
    //--------PROPS----------------------------------------------------//
    const { options } = props

    //--------------HOOKS-----------------------------------------------------//
    const { componentsDOM, handleSubmit, inputChanged} = useBussinessProfileForm({ options })


    //----------------------LAYOUT-------------------------------------------//
    return(
        <Grid className="business__form" w100 maxWidth="22.85rem" selfX="center" gap="1.9rem">
            { componentsDOM }
            { inputChanged && <Button selfCenter onClick={handleSubmit}>Guardar</Button>}
        </Grid>
    )
}