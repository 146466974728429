import $ from 'jquery'; 
const getRelativePosition = (position, relativeNode, nodeToPositionate) => {
    let positionResult = {}
    if(nodeToPositionate !== undefined && relativeNode !== undefined) {
        const windowWidth = window.innerWidth, windowHeight = window.innerHeight
        const tooltipWidth = $(nodeToPositionate).outerWidth(true), tooltipHeight = $(nodeToPositionate).outerHeight(true)
        const nodeWidth = $(relativeNode).outerWidth(true), nodeHeight = $(relativeNode).outerHeight(true)
        const nodePosition = relativeNode.getBoundingClientRect()
        
        switch(position){
            case 'top': 
                positionResult['bottom'] = windowHeight - nodePosition.top
                positionResult['left'] = nodePosition.left + (nodeWidth / 2) - (tooltipWidth / 2)
                break
            case 'left': 
                positionResult['right'] = windowWidth - nodePosition.left
                positionResult['top'] = nodePosition.top + (nodeHeight / 2) - (tooltipHeight / 2)
                break
            case 'right': 
                positionResult['left'] = nodePosition.right
                positionResult['top'] = nodePosition.top + (nodeHeight / 2) - (tooltipHeight / 2)
                break
            default: 
                positionResult['top'] = nodePosition.bottom
                positionResult['left'] = nodePosition.left + (nodeWidth / 2) - (tooltipWidth / 2)
        }
    }
    return positionResult
}

export { getRelativePosition }