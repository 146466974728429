import React, { Fragment, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'

import { useMessageBoxContext } from 'hooks'

import { Button, FilterTable, Grid, Text, } from 'components'

import './admin_company_invoices.scss'
import { mdAnimations } from 'scripts/generalVariables'

import PayInvoiceModal from './PayInvoiceModal/PayInvoiceModal'

const dateOptions = {
    day: 'numeric', month: 'short', year: 'numeric',
}

const invoiceColumns = [
    { name: 'date', displayName: 'Fecha', format: date => (new Date(date.split('T')[0] + 'T00:00:00')).toLocaleString('es-MX', dateOptions) },
    { name: 'businessName', displayName: 'Razón social' },
    { name: 'concept', displayName: 'Concepto' },
    { name: 'folioNumber', displayName: 'Folio' },
    { name: 'amount', displayName: 'Monto', format: amount => global.parseCurrency(amount), sortMethod: global.sortByNumber },
    { name: 'status', displayName: 'Estado' },
]

const showMBCannotEditInvoice = (showMB, hideMB) => {
    showMB({
        title: 'El estado de la factura no permite realizar este cambio',
        animation: mdAnimations.INFO,
        buttons: <Button onClick={hideMB}>Entendido</Button>
    })
}

const AdminCompanyInvoices = () => {
    /*------------------------------------STATE--------------------------------*/
    const [invoices, setInvoices] = useState([])
    const [isLoadingInvoices, setIsLoadingInvoices] = useState(false)
    const [openPayInvoiceModal, setOpenPayInvoiceModal] = useState(false)
    const [selectedInvoice, setSelectedInvoice] = useState({})

    /*------------------------------------REF--------------------------------*/
    const invoicesRef = useRef([])

    /*------------------------------------HOOKS--------------------------------*/
    const { companyId } = useParams()
    const navigate = useNavigate()
    const { showConfirmMsg, showMB, hideMB } = useMessageBoxContext()

    /*------------------------------------FUNCTIONS--------------------------------*/
    const getInvoices = async () => {
        setIsLoadingInvoices(true)
        try {
            const endpoint = companyId !== undefined ? `/admin/companies/${companyId}/invoices` : '/admin/companies/all/invoices'
            const response = await axios.get(endpoint)
            if (response.data) {
                setInvoices(response.data.invoices)
                invoicesRef.current = response.data.invoices
            }
        } catch (error) {
            console.error('GET_INVOICES_ERROR')
        }
        setIsLoadingInvoices(false)
    }

    const handleChangeFilter = (filter) => {
        let filteredInvoices = [...invoicesRef.current]
        if (filter !== '') {
            const filterRegexp = RegExp(`${filter.toUpperCase()}`)
            filteredInvoices = filteredInvoices.filter(({ date, businessName, concept, folioNumber, amount, status }) => (
                (date.search(filterRegexp) >= 0)
                || (amount.search(filterRegexp) >= 0)
                || (businessName.toUpperCase().search(filterRegexp) >= 0)
                || (concept.toUpperCase().search(filterRegexp) >= 0)
                || (folioNumber.toUpperCase().search(filterRegexp) >= 0)
                || (status.toUpperCase().search(filterRegexp) >= 0)
            ))
        }
        setInvoices(filteredInvoices)
    }

    const toggleOpenPayInvoiceModal = (invoice) => {
        if (invoice && ['PAID', 'CANCELLED'].includes(invoice.status)) {
            showMBCannotEditInvoice(showMB, hideMB)
            return
        }
        setSelectedInvoice(invoice)
        setOpenPayInvoiceModal(openPayInvoiceModal => !openPayInvoiceModal)
    }

    const cancelInvoice = (invoice) => {
        if (['PAID', 'CANCELLED'].includes(invoice.status)) {
            showMBCannotEditInvoice(showMB, hideMB)
            return
        }
        showConfirmMsg({
            title: 'Esta factura será cancelada ante el SAT.', content: <Text bold>¿Deseas continuar?</Text>,
            confirm: async () => {
                try {
                    const endpoint = invoice.isTokenInvoice ? `/admin/tokens/cancell/${invoice.id}/income-invoice` : `/admin/companies/invoices/${invoice.id}/cancell`
                    const response = await axios.delete(endpoint, { appSpinner: true })
                    if (response.data.success) {
                        setInvoices(invoices => {
                            const invoicesResult = [...invoices]
                            const invoiceIdx = invoicesResult.findIndex(e => e.id === invoice.id)
                            invoicesResult[invoiceIdx]['status'] = 'CANCELLED'
                            return invoicesResult
                        })
                    }
                } catch (error) {
                    console.error('MARK_INVOICE_PAID_ERROR')
                }
            }
        })
    }

    /*------------------------------------EFFECT--------------------------------*/
    useEffect(() => {
        getInvoices()
        // run effect once
        // eslint-disable-next-line
    }, [])

    /*------------------------------------RENDER--------------------------------*/
    const invoiceRowButtons = [
        { icon: 'copy', onClick: toggleOpenPayInvoiceModal },
        { icon: 'trash', onClick: cancelInvoice }
    ]

    return (
        <Fragment>
            <Button alignSelf='start' onClick={() => navigate(global.getCurrentPath())}>Regresar</Button>
            <Grid columns='1fr auto' gap='1rem' className='admin_company_invoices_page'>
                <Grid>
                    <Text bold align='center' size={7}>Facturación</Text>
                    <FilterTable
                        columns={invoiceColumns}
                        rows={invoices}
                        isLoadingRows={isLoadingInvoices}
                        onChangeFilter={handleChangeFilter}
                        rowButtons={invoiceRowButtons}
                        columnsTemplate='1fr 2fr 3fr 1fr 1fr 1fr'
                    />
                </Grid>
                {/* <Grid padding='6rem 0' contentY='start'>
                    <Tooltip text='Datos de facturación' position='left'>
                        <IconButton icon='edit' onClick={() => navigate(global.getCurrentPath('/billing'))} />
                    </Tooltip>
                </Grid> */}
            </Grid>
            {openPayInvoiceModal &&
                <PayInvoiceModal
                    toggleOpenPayInvoiceModal={toggleOpenPayInvoiceModal}
                    setInvoices={setInvoices}
                    invoice={selectedInvoice}
                />
            }
        </Fragment >
    )
}

export default AdminCompanyInvoices
