import { useSelector } from "react-redux"
import { Navigate, Outlet, useLocation } from "react-router-dom"
import { adminRoutes, routes } from "./routes"

const RequireNoAuth = () => {
    const { app, auth } = useSelector(store => store)
    const { authenticated } = auth
    const { isAdminMode } = app
    const location = useLocation()

    const homePath = location.state?.from?.pathname || (isAdminMode ? adminRoutes.home.path : routes.home.path)

    return !authenticated ? <Outlet /> : <Navigate to={homePath} replace />
}

export default RequireNoAuth