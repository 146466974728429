import React from 'react'

// import SideMenuUsers from './Categories/Users_Profiles'
import Branches from '../Categories/Branches'
import Company from '../Categories/Company'


/*<SideMenuUsers />*/
const Screens = ({
    0: {
        icon: 'MiEmpresa', 
        text: 'Mi empresa', 
        component: <Company />
    },
    1: {
        icon: 'Sucursales', 
        text: 'Sucursales / Plantas', 
        component: <Branches />
    },
    2: {
        icon: 'Permisos',
        text: 'Permisos y accesos',
        component: <Branches />
    },
    3: {
        icon: 'Cuenta-usuarios', 
        text: 'Cuentas de usuarios',
        component: <Branches />
    },
    4: {
        icon: 'Configuracion_modulos', 
        text: 'Configuraciones', 
        component: <Branches />
    },
    5: {
        icon: 'Compras',
        text: 'Compras y Pagos', 
        component: <Branches />
    },
    6: {
        icon: 'Desarrolladores',
        text: 'Desarrolladores', 
        component: <Branches />
    },
})

export default Screens