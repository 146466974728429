import React from 'react'
import { Grid, Text } from 'components'


const IndustrialMedicine = () =>{

    

    return(
        <Grid>
            <Text bold size="6" align="center">MEDICINA INDUSTRIAL</Text>
        </Grid>
    )
}

export default IndustrialMedicine