import React, { useCallback } from 'react'

import { Grid, Button, FormHandler, Flex, Text } from 'components'

const RecordGroupForm = props => {

    const {
        formData, setFormData, formRef, fields, formTitle, closeForm,
        handleSave, onValidateForm, onChangeFormData
    } = props

    const handleChangeForm = useCallback(formValue => {
        setFormData(formValue)
        !!onChangeFormData && onChangeFormData(formData, formRef.current, Object.keys(formValue())[0])
    }, [formData, formRef, onChangeFormData, setFormData])

    const handleClickSave = useCallback(() => {
        if (!formRef.current.validate()) return
        if (onValidateForm && !onValidateForm(formData, formRef.current)) return

        handleSave(formData)
    }, [formData, formRef, handleSave, onValidateForm])
    const isNewRecord = formData.id < 0

    return (
        <Grid className='record_group__form' gap='1em' padding='1.75rem 2.25rem'>
            <Text medium size='4' align='center'>{formTitle !== "Registro" ? formTitle : isNewRecord ? `Nuevo ${formTitle}` : `Editar ${formTitle}`}</Text>
            <Grid w100 gap='1em'>
                <FormHandler {...{ ref: formRef, formData, setFormData: handleChangeForm, fields }} />
            </Grid>
            <Flex align='center' justify='center' style={{ marginTop: '1em' }} wrap>
                <Button onClick={closeForm} variant="outlined" style={{ margin: '0.5em' }}>Cancelar</Button>
                <Button onClick={handleClickSave} style={{ margin: '0.5em' }}>Guardar</Button>
            </Flex>
        </Grid>
    )
}

export default RecordGroupForm