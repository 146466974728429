import {
    ChangePassword, Home, HomeNoSession,
    Profile, RecoverPasswordForm, TestingPage, IndustrialMedicine, Payment, AdminLogin, AdminHome, AdminCompanyInvoices, AdminCompanyInfo, CompanyBillingInfo, CompanyModuleInfo, CompanyTokenInfo, AdminSupport, AdminInvoices
} from 'Pages'

/* (If apply)
 * headerRoutes: string[] ->    links that will be displayed in header
 * sideMenuRoutes: string[] ->  links that will be displayed in side menu
 */
export const headerRoutes = []
export const sideMenuRoutes = []

/* routes access types
 * publicRoutes: string[]   ->      does not matter if a user session exists
 * requireNoAuthRoutes: string[] -> routes require a user session does not exist
 * requireAuthRoutes: string[] ->   routes require a user session exists
 */

export const publicRoutes = ['testing_page']
export const requireNoAuthRoutes = ['start_page', 'recover_password']

export const requireAuthRoutes = ['home', 'profile', 'change_password', 'payment', 'industrial_medicine']

export const requireNoAuthAdminRoutes = ['login']

export const requireAuthAdminRoutes = [
    'home', 'company_info', 'company_invoices', 'company_billing', 'module_info', 'token_info',
    'invoices',
    'support',
]

/**
 * Routes object
 * path -> text displayed in url
 * linkName -> text to display in app links (header, sidemenu)
 */

export const routes = {
    testing_page: {
        path: '/testing_page',
        linkName: 'Testing Page',
        element: <TestingPage />
    },
    login: {
        path: '/start/login',
    },
    start_page: {
        path: '/start/:section',
        linkName: 'home',
        element: <HomeNoSession />
    },
    recover_password: {
        path: '/recover_password/:tokenPassword',
        linkName: 'Recover password',
        element: <RecoverPasswordForm />
    },
    payment: {
        path: '/payment',
        linkName: 'Payment',
        element: <Payment />
    },
    home: {
        path: '/',
        linkName: 'Home',
        element: <Home />
    },
    change_password: {
        path: '/change_password',
        linkName: 'Change password',
        element: <ChangePassword />
    },
    profile: {
        path: '/profile',
        linkName: 'Profile',
        element: <Profile />,
        breadcrumbName: 'Mi cuenta'
    },
    industrial_medicine: {
        path: '/industrial_medicine',
        linkName: 'Medicina Industrial',
        element: <IndustrialMedicine />,
        breadcrumbIcon: 'IconoMedicina'
    },
}

export const adminRoutes = {
    login: {
        path: '/admin/login',
        element: <AdminLogin />,
    },
    home: {
        path: '/',
        element: <AdminHome />,
    },
    company_info: {
        path: '/admin/company_info/:companyId',
        element: <AdminCompanyInfo />,
    },
    company_invoices: {
        path: '/admin/company_info/:companyId/invoices',
        element: <AdminCompanyInvoices />,
    },
    company_billing: {
        path: '/admin/company_info/:companyId/billing',
        element: <CompanyBillingInfo />,
    },
    module_info: {
        path: '/admin/module_info',
        element: <CompanyModuleInfo />,
    },
    token_info: {
        path: '/admin/token_info',
        element: <CompanyTokenInfo />,
    },
    invoices: {
        path: '/admin/invoices',
        element: <AdminInvoices />,
    },
    support: {
        path: '/admin/support',
        element: <AdminSupport />,
    },
}

export const adminNoBackRoutes = [
    adminRoutes.login.path, adminRoutes.home.path, adminRoutes.module_info.path, adminRoutes.token_info.path,
    adminRoutes.company_invoices.path, adminRoutes.company_billing.path,
    adminRoutes.invoices.path,
    adminRoutes.support.path,
]

/**
 * Not render header on this routes
 */
export const noHeaderRoutes = [
    ...requireNoAuthRoutes.map(route => routes[route].path),
    ...publicRoutes.map(route => routes[route].path),
]

/**
 * routes that are not applied to redirect after sign in
 */
export const noRedirectPaths = [routes.change_password.path]
