
import React, { forwardRef, memo, useCallback, useRef } from 'react'
import { Grid, LottieAnimationPlayer , Text, Tooltip, } from 'components'

export default memo ( forwardRef ( function SideMenuCategoriesNavigatorOption (props, ref) {
    const { text, icon, selected, onClick } = props
    
    const animationRef = useRef()
    
    const playAnimation = useCallback(() => { animationRef.current.play() }, [])
    
    let className = "side_menu__categories__option__inner_grid" 
    if(selected) className += ' side_menu__categories__option__inner_grid--selected'
    
    /*------------------------------------RENDER-----------------------------------*/
    
    return (
        <Tooltip onMouseEnter={playAnimation} text={text} position='right' tooltipClassName='side_menu__categories__option__inner_grid__tooltip'>
            <Grid w100 h100 direction='column' contentX='start' className='side_menu__categories__option' ref={ref}>
                <Grid h100 itemsY="center" columns='auto 1fr' rows='1fr' {...{className, onClick}}>
                    <LottieAnimationPlayer {...{
                        ref: animationRef, 
                        animation: icon, 
                        className: 'side_menu__categories__option__inner_grid__icon'
                        }}
                    />
                    
                    <Text className='side_menu__categories__option__inner_grid__text' color='second' 
                        w100 medium align='left'>{text}</Text>
                    
                </Grid>
                
            </Grid>   
        </Tooltip>
    )
} ) )