export const screenSizes = {
    'mobile': 0,
    'tablet': 480,
    'laptop': 768,
    'desktop': 1024,
    'tv': 1440,
    '4k': 2500,
}

export const notValidTokenCodes = [
    'MDT_APP_TOKEN_NOT_VALID',
    'FST_JWT_AUTHORIZATION_TOKEN_INVALID',
    'FST_JWT_AUTHORIZATION_TOKEN_EXPIRED',
    'FST_JWT_NO_AUTHORIZATION_IN_HEADER',
    'FAST_JWT_INVALID_ALGORITHM',
]

export const mdAnimations = {
    SUCCESS: 'MD-Exito',
    ERROR: 'MD-Error',
    ALERT: 'MD-Alerta',
    INFO: 'MD-Informativo'
}

// eslint-disable-next-line
export const isAdminSubdomain = /:\/\/([^\/]+)/.exec(window.location.href)[1].split('.')[0] === 'admin'