import React, {memo} from 'react'

export default memo ( function Img (props) {
    let {
        src = '',
        className = '',
        width = '',
        height = '',
        w100 = false,
        h100 = false,
        maxWidth = '',
        fit = '',
        justifySelf = '',
        margin = '',
        
    } = props
    
    if(w100) width = '100%'
    if(h100) height = '100%' 
    
    let style = {
        width, 
        height,
        maxWidth,
        objectFit: fit,
        justifySelf,
        margin
    }
    
    return <img alt="" {...{src, className: `img ${className}`, style}} />
})