import React, { forwardRef, memo, useCallback } from 'react'
import reactFastCompare from 'react-fast-compare'

import './toggle.scss'

const Toggle = (props, ref) => {
    let {
        className = ''
    } = props
    const {
        label1,
        label2,
        value = false,
        onChange,
        notInactive = false,
    } = props

    const handleChange = useCallback(() => {
        onChange(!value)
    }, [onChange, value])

    className += ' toggle_switch'
    if (notInactive) className += ' not_inactive'

    return (
        <div className={className}>
            {label1 && <label className="toggle_switch__label label1">{label1}</label>}
            <div className={`toggle_switch__switch${value ? ' active' : ''}`} onClick={handleChange}>
                <span className='toggle_switch__slider'></span>
            </div>
            {label2 && <label className="toggle_switch__label label2">{label2}</label>}
        </div>
    )
}

export default memo(forwardRef(Toggle), reactFastCompare)