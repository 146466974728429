import React, { useState, useEffect, forwardRef, useCallback, } from 'react'
import useSideMenuContext from 'hooks/useSideMenuContext'

import {Grid } from 'components'
import SideMenuCategoriesNavigator from './Navigator'
import Screens from './Navigator/Screens'

import './side_menu.scss'

/*------------------------------------COMPONENT---------------------------------------*/
export default forwardRef ( function SideMenu (props, ref) {
    

    const { isOpen, sideMenu } = useSideMenuContext()
    /*--------------------------------------STATE----------------------------------------*/
        const [category, setCategory] = useState(-1)
        
        
    /*-------------------------------------EFFECTS---------------------------------------*/
        useEffect(() => {
            setCategory(-1)
        }, [isOpen])
        
        
    /*--------------------------------EXTERNAL FUNCTIONS---------------------------------*/
        
    /*--------------------------------------RENDER---------------------------------------*/
        let style = {}, className = 'side_menu'
        if(isOpen) className += ' side_menu--open'
        
        
        return(
            <Grid contentY='start' className={`${className} ${category >= 0 ? 'side_menu--minimize' : ''}`}
                direction='column' rows='100%' ref={sideMenu} style={style}>
                
                <SideMenuCategoriesNavigator onChange={useCallback(setCategory,[setCategory])} category={category} />
                
                <Grid className={`side_menu__content ${category >= 0 ? 'side_menu__content--open' : ''}`}
                    h100 w100 rows="100%" columns='100%'>
                    
                    { category !== -1 && Screens[category].component }
                    {/*Screens(category).map((s, i) =>
                        <Grid w100 h100 columns='100%' rows='100%' key={i} style={category == i ? {} : {display: 'none'}}>
                            {s.component}
                        </Grid>
                    )*/}
                </Grid>
            </Grid>
        )
})