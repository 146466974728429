import React, { useCallback, useEffect, useRef, useState, } from 'react'
import axios from 'axios'
import { Grid, Text, MessageBox, Button, Divisor } from 'components'
import { useSelector, useDispatch } from 'react-redux'
import { setCompany } from 'redux/reducers/auth/authSlice'

import './company.scss'
import BusinessProfileForm from './BusinessProfileForm'
import CompanyTheme from './CompanyTheme/CompanyTheme'

const Company = (props) => {
    const messageBoxRef = useRef()
    const companyLogoRef = useRef()
    const companyIconRef = useRef()
    const dispatch = useDispatch()

    const [industryTypesOptions, setIndustryTypeOptions] = useState({})

    useEffect(() => {
        axios.get('general/my_company/get_industry_types', {}, { appSpinner: true })
            .then((response) => {
                setIndustryTypeOptions(response.data.industryTypes)
            })
            .catch(err => {
                console.error('GET_INDUSTRY_TYPES_ERROR', err)
            })
    }, [])

    const { auth } = useSelector(store => store)


    const changeCompanyLogo = useCallback((event) => {
        const ALLOWED_FILE_LIMIT_SIZE = 5242880; //5MB
        const { size } = event.target.files[0]

        if (ALLOWED_FILE_LIMIT_SIZE >= size) {
            const options = {
                url: 'company/account/logo',
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                data: {
                    formData: { file: event.target.files[0] },
                },
                appSpinner: true
            }
            axios(options)
                .then(response => {
                    companyLogoRef.current.value = ""
                    dispatch(setCompany({ companyLogoUrl: response.data.photoUrl }))
                })
                .catch(err => {
                    console.error('SET_ACCOUNT_LOGO_ERROR', err)
                })
        } else {
            messageBoxRef.current.show({
                content: <Text medium align="center">La foto es demasiado grande, selecciona una con un tamaño menor a 5 Mb.</Text>,
                buttons: <Button onClick={() => { messageBoxRef.current.hide() }}>Entendido</Button>
            })
        }
    }, [dispatch])

    const changeCompanyIcon = useCallback((event) => {
        const ALLOWED_FILE_LIMIT_SIZE = 2097152; //2MB
        const { size } = event.target.files[0]

        if (ALLOWED_FILE_LIMIT_SIZE >= size) {
            const options = {
                url: 'company/account/icon',
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                data: {
                    formData: { file: event.target.files[0] },
                },
                appSpinner: true
            }
            axios(options)
                .then(response => {
                    companyIconRef.current.value = ""
                    dispatch(setCompany({ companyIconUrl: response.data.photoUrl }))
                })
                .catch(err => {
                    console.error('SET_COMPANY_ICON_ERRROR', err)
                })
        } else {
            messageBoxRef.current.show({
                content: <Text medium align="center">La foto es demasiado grande, selecciona una con un tamaño menor a 2 Mb.</Text>,
                buttons: <Button onClick={() => { messageBoxRef.current.hide() }}>Entendido</Button>
            })
        }
    }, [dispatch])


    return (
        <Grid style={{ overflowY: 'scroll' }} padding="1.71rem" contentY="start">
            <MessageBox ref={messageBoxRef} />
            <Grid gap="1.76rem">
                <Text size="4" align="center">Mi Empresa</Text>
                <Text bold align="center">Perfil Empresarial</Text>
                <Grid className="company_logos_container">
                    <Grid w100 selfX="center" padding="1.71rem 1.85rem" className={auth.company?.companyLogoUrl === "" ? "add_company_logo__container" : "add_company_logo__container_personalized"}>
                        <input
                            className='company_logo_input'
                            type="file"
                            accept="image/*"
                            ref={companyLogoRef}
                            name="myImage"
                            onChange={changeCompanyLogo}
                            title=""
                        />
                        {auth.company?.companyLogoUrl === "" ?
                            <Grid>
                                <Grid rows="auto auto 1fr" style={{ marginTop: '2.07rem' }} gap="0.357rem">
                                    <Text bold color="second" size="6" align="center">Agrega el Logotipo de la empresa</Text>
                                    <Text size="4" style={{ 'font-weight': '400' }} align="center">Medidas recomendadas 1200px x 450px</Text>
                                    <Text align="center">Este aparecerá en documentos a imprimir.</Text>
                                </Grid>

                            </Grid>
                            :


                            <Grid className="company_logo__personalized">
                                <Grid className="company_logo__change_photo">
                                    <Grid>
                                        <Text bold color="second" size="4" align="center">Cambia el Logotipo de la empresa</Text>
                                    </Grid>
                                </Grid>
                                <img alt="" src={auth.company?.companyLogoUrl} className='company_logo_photo' />
                            </Grid>

                        }
                    </Grid>
                    <Grid w100 selfX="center" maxWidth="43.57rem" style={{ maxHeight: '3.57rem' }}>
                        <Grid className={auth.company?.companyIconUrl === "" ? "add_company_icon__container" : "add_company_icon__container_personalized"}>
                            <input
                                className='company_icon_input'
                                type="file"
                                accept="image/*"
                                ref={companyIconRef}
                                name="myImage"
                                onChange={changeCompanyIcon}
                                title=""
                            />
                            {auth.company?.companyIconUrl === "" ?
                                <Grid>
                                    <Text bold color="second" align="center" size="2">Agrega el icono de tu empresa</Text>
                                    <Text regular align="center" size="2">Recomendado 180 x 180 px</Text>
                                </Grid>
                                :
                                <Grid className="company_icon__personalized" gap="0.15em">
                                    <Grid className="company_icon__change_photo">
                                        <Text bold align="center" color="second" size="2">Cambia el icono de tu empresa</Text>
                                    </Grid>
                                    <img alt="" src={auth.company?.companyIconUrl} className='company_icon_photo' />
                                </Grid>
                            }

                        </Grid>
                    </Grid>
                </Grid>

                <Grid w100 maxWidth="22.85em" selfX="center" gap="2.60em">
                    <BusinessProfileForm options={industryTypesOptions} />
                </Grid>

                <Divisor margin="8px 0px" />

                <CompanyTheme />

            </Grid>
        </Grid>
    )
}

export default Company
