import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { routes } from 'routing/routes'
import { Divisor, Grid, Img, LottieAnimationButton, IconButton, RouterLink } from 'components'

import useSideMenuContext from 'hooks/useSideMenuContext'
import useProfileMenuContext from 'hooks/useProfileMenuContext'

import { logoS3 } from 'scripts/assetsS3'

import './header.scss'
import Breadcrumb from './Breadcrumb/Breadcrumb'
import ProfileMenu from 'Layout/ProfileMenu/ProfileMenu'
import { Fragment } from 'react'
const DEFAULT_PROFILE_PHOTO = "https://magiei2.s3.us-east-2.amazonaws.com/public/img/icons/icono_usuario.svg"
const Header = () => {
    // const [photoUrl, setPhotoUrl] = useState(DEFAULT_PROFILE_PHOTO)
    const location = useLocation()
    const burgerButtonAnimation = useRef()
    // const sideMenu = useRef()
    const { toggleSideMenu, isOpen, burgerButton } = useSideMenuContext()
    const { toggleProfileMenu, profileMenuButton } = useProfileMenuContext()

    const handleOpenSideMenu = () => {
        toggleSideMenu()
    }

    const handleOpenProfileMenu = () => {
        toggleProfileMenu()
    }

    useEffect(() => {
        burgerButtonAnimation.current.play(isOpen ? 1 : 2)
        // onChangeSideMenu()
    }, [isOpen])


    const { auth } = useSelector(store => store)

    const imageClassName = auth.user !== null ? ((auth.user.infoUser?.photoUrl === DEFAULT_PROFILE_PHOTO || auth.user.infoUser?.photoUrl === "") ? "user_info__default" : "account__user_photo") : "account__user_photo"

    const logoImage = logoS3('iconMaGiEi')
    return (
        <Fragment>
            <ProfileMenu />
            <Grid columns="1fr 2fr 1fr" className="header" align="center">
                <Grid contentX="start" direction="column" gap="1.142rem" itemsY="center">
                    <Grid className="header__side_menu_button_container">
                        <LottieAnimationButton
                            className="header__side_menu_button_container__content"
                            animation='Hamburguesa'
                            onClick={handleOpenSideMenu}
                            ref={{ refButton: burgerButton, refAnimation: burgerButtonAnimation }}
                        />
                    </Grid>
                    <Divisor vertical thickness="2px" />
                    {location.pathname === '/' ?
                        <RouterLink to={routes.industrial_medicine.path}>
                            <IconButton icon="IconoMedicina" size="1.5" filter='active' />
                        </RouterLink>
                        :
                        <Breadcrumb />
                    }

                </Grid>
                <RouterLink style={{justifyContent:"center"}} to={routes.home.path}>
                    <Img src={logoImage} className="header__logo_image" />
                </RouterLink>
                <Grid contentX="end" direction="column" itemsY="center" gap="1.142em">
                    <Grid className="header__notification_container">
                        <IconButton filter='active' icon="notification" size="1.715" />
                    </Grid>
                    <Grid ref={profileMenuButton} onClick={handleOpenProfileMenu} className="header__button">
                        <img alt="" className={imageClassName} src={auth.user?.infoUser?.photoUrl || DEFAULT_PROFILE_PHOTO} />
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default Header
