import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

import { useSelector, useDispatch } from 'react-redux'
import { logout, setSettingsUser } from 'redux/reducers/auth/authSlice'

import useProfileMenuContext from 'hooks/useProfileMenuContext'

import { Button, Divisor, Grid, Icon, Img, Text, Toggle } from 'components'

import './profile_menu.scss'
import { routes } from 'routing/routes'

const DEFAULT_PROFILE_PHOTO = "https://magiei2.s3.us-east-2.amazonaws.com/public/img/icons/icono_usuario.svg"

const ProfileMenu = () => {
    /*------------------------------------STORE/CONTEXT--------------------------------*/
    const { auth } = useSelector(store => store)
    const { user } = auth
    const { toggleProfileMenu, isOpenProfileMenu, profileMenu } = useProfileMenuContext()

    /*------------------------------------STATE--------------------------------*/
    const [darkMode, setDarkMode] = useState(user.settingsUser.darkMode)

    /*------------------------------------HOOKS--------------------------------*/
    const navigate = useNavigate()
    const dispatch = useDispatch()

    /*------------------------------------FUNCTIONS--------------------------------*/
    const handleLogout = useCallback(() => {
        toggleProfileMenu()
        dispatch(logout())
    }, [dispatch, toggleProfileMenu])

    const handleChangeDarkMode = useCallback(async (value) => {
        try {
            const response = await axios.put('/user/account/setting/darkMode', { value }, { appSpinner: true })
            if (!response.data) throw new Error('Error saving darkmode')
            dispatch(setSettingsUser(response.data))
            setDarkMode(value)
            localStorage.setItem('darkMode', value)
            document.body.classList.toggle('dark', value)
        } catch (error) {
            console.error('SET_DARK_MODE_ERROR', error)
        }
    }, [dispatch])

    /*------------------------------------RENDER--------------------------------*/
    const imageClassName = auth.user !== null ?
        (auth.user.infoUser?.photoUrl === DEFAULT_PROFILE_PHOTO || auth.user.infoUser?.photoUrl === "") ? "user_info__default" : "account__user_photo"
        :
        "account__user_photo"

    return (
        <Grid className={`profile_menu ${isOpenProfileMenu ? 'open' : ''} `} ref={profileMenu} gap="1.2em" padding="1em" itemsX="center">
            <Img className={imageClassName} src={auth.user?.infoUser?.photoUrl || DEFAULT_PROFILE_PHOTO} />
            <Grid gap="0.2em" itemsX="center">
                <Text medium>{auth.user?.infoUser?.name}</Text>
                <Text medium color="second" size="2">{auth.user?.infoUser?.email}</Text>
            </Grid>
            <Divisor width='calc(100% + 2em)' />
            <Grid w100 gap="0.7em">
                <Text medium className="profile_menu__option" onClick={() => { toggleProfileMenu(); navigate(routes.profile.path) }}>Mi cuenta</Text>
                <Text medium className="profile_menu__option">Acerca de</Text>
                <Text medium className="profile_menu__option">Tutoriales y Capacitaciones</Text>
                <Text medium className="profile_menu__option">Ayuda y Soporte</Text>
            </Grid>
            <Divisor width='calc(100% + 2em)' />
            <Grid gap="0.7em">
                <Text medium align="center" color="second">Tema</Text>
                <Toggle
                    notInactive
                    className='dark_mode_toggle'
                    label1={<><Text medium color="second" size="2">Claro</Text><Icon icon='icon-light' filter='seventh' /></>}
                    label2={<><Icon icon='icon-dark' filter='seventh' /><Text medium color="second" size="2">Oscuro</Text></>}
                    value={darkMode}
                    onChange={handleChangeDarkMode}
                />
            </Grid>
            <Divisor width='calc(100% + 2em)' />
            <Button variant="outlined" onClick={handleLogout}>Cerrar sesión</Button>
        </Grid>
    )
}

export default ProfileMenu