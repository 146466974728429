import React, { useCallback, useMemo, useState, useRef } from 'react'
import { Grid, Text, Flex, AccordionGroup, Icon, Link } from 'components'
import { useClickOutside } from 'hooks'


import './direct_access_menu.scss'
import DASectionAccessCode from './DASectionAccessCode/DASectionAccessCode'

const DirectAccessMenu = (props) => {
    const { showAccessMenuMovil, setShowAccessMenuMovil } = props
    const [isSectionOpen, setIsSectionOpen] = useState(false)

    const menuRef = useRef()
    const accordionGroupRef = useRef()

    const handleToggleAccordion = useCallback((isOpen) => {
        setIsSectionOpen(isOpen)
    }, [])

    useClickOutside(menuRef, () => {
        if (isSectionOpen) {
            accordionGroupRef.current.setOpenAccordion(0)
            setIsSectionOpen(false)
        }
    })

    const accordionGroupContent = useMemo(() => [
        {
            toggleElement: <Text bold size="2" className='direct_access__accordion_title' align='center'>Encuestas de <span> NOM-035 </span></Text>,
            content: <DASectionAccessCode />,
            className: 'direct_access__section_accordion'
        },
        {
            toggleElement: <Text bold size="2" className='direct_access__accordion_title' align='center'>Examen de Nuevo <span> Ingreso </span></Text>,
            content: <DASectionAccessCode />,
            className: 'direct_access__section_accordion'
        }
    ], [])

    return (
        <Grid ref={menuRef} className={`direct_access_menu${isSectionOpen ? ' open' : ''} ${showAccessMenuMovil ? 'movil_menu' : ''}`}>
            <Grid itemsY="center" className='direct_access_menu__back' columns='auto 1fr' onClick={() => { setShowAccessMenuMovil(false) }} >
                <Icon icon='arrow' direction='bottom' size={1.5} filter="first" />
                <Link bold align='left'>Regresar</Link>
            </Grid>
            <Flex className='direct_access__title'>
                <Text regular size="2" align='center' color="second">ACCESOS DIRECTOS</Text>
            </Flex>
            <AccordionGroup ref={accordionGroupRef} accordions={accordionGroupContent} onToggle={handleToggleAccordion} />
        </Grid>
    )
}

export default DirectAccessMenu