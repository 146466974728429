import { Button, Flex, FormHandler, Grid } from 'components'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { adminLogin } from 'redux/reducers/auth/authSlice'


const formFields = {
    email: {
        input: 'text_field',
        inputProps: {
            label: 'Usuario',
            required: true,
            type: 'email',
            errorMessage: 'El campo "Usuario" es requerido'
        }
    },
    password: {
        input: 'text_field',
        inputProps: {
            label: 'Contraseña',
            required: true,
            type: 'password',
            errorMessage: 'El campo "Contraseña" es requerido'
        }
    },
}

const AdminLogin = () => {
    /*----------------------------STATE----------------------------*/
    const [formData, setFormData] = useState({ email: '', password: '' })

    /*----------------------------HOOKS----------------------------*/
    const dispatch = useDispatch()
    const formHandlerRef = useRef()

    /*----------------------------FUNCTIONS----------------------------*/
    const handleSubmit = e => {
        e.preventDefault()

        if (formHandlerRef.current.validate()) {
            dispatch(adminLogin(formData))
        }
    }

    return (
        <Flex w100 h100 align='center' justify='center' className='admin_login_page'>
            <form onSubmit={handleSubmit}>
                <Grid itemsX='center' gap='1rem' >
                    <FormHandler ref={formHandlerRef} fields={formFields} formData={formData} setFormData={setFormData} />
                    <Button>Iniciar sesión</Button>
                </Grid>
            </form>
        </Flex>
    )
}

export default AdminLogin
