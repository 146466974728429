import { configureStore } from '@reduxjs/toolkit'
import authSlice from './reducers/auth/authSlice'
import appSlice from './reducers/app/appSlice'
import appThemeSlice from './reducers/apptheme/appThemeSlice'

export default configureStore({
    reducer: {
        auth: authSlice,
        app: appSlice,
        appTheme: appThemeSlice,
    },
    //*******************************UNCOMMENT FOR PRODUCTION*******************************
    devTools: process.env.NODE_ENV === 'development'
}) 