import React from 'react'
import { Outlet, matchPath, useLocation, useNavigate } from 'react-router-dom'

import { Button, Flex, Grid } from 'components'

import { adminNoBackRoutes, adminRoutes } from 'routing/routes'

import AdminSideMenu from './AdminSideMenu/AdminSideMenu'

import './admin_layout.scss'

const AdminLayout = () => {
    const navigate = useNavigate()

    const location = useLocation()
    const showBackBtn = !adminNoBackRoutes.find(route => matchPath({ path: route }, location.pathname))

    return (
        <Grid className='admin_app_container' columns='auto 1fr'>
            <AdminSideMenu />
            <Flex padding='1rem' direction='column'>
                {showBackBtn && <Button alignSelf='start' onClick={() => navigate(adminRoutes.home.path)}>Regresar</Button>}
                <Outlet />
            </Flex>
        </Grid>
    )
}

export default AdminLayout
