import React, { forwardRef, memo, useCallback, useImperativeHandle, useState } from 'react'
import reactFastCompare from 'react-fast-compare'

import { useMessageBoxContext } from 'hooks'

import { Flex, Grid, Slider } from 'components'

import RecordGroupList from './RecordGroupList'

import './record_group.scss'

const RecordGroup = (props, ref) => {
    /*--------------------------------------PROPS-----------------------------------*/
    const {
        columns = [], //[{name:'Display name', helpText:'Help Text'}]
        value = {},
        onChange, // function(newState, {...modifiedRecord, type: 'add'/'delete'})
        onChangeRecord,
        formContent,
        min,
        max,
        deletingMessage = '¿Estás seguro que quieres borrar este registro?',
        errorMessage = '',
        maxWidth,
        style,
    } = props

    let { className = '' } = props

    /*--------------------------------------STATE-----------------------------------*/
    const [inputStatus, setInputStatus] = useState('normal')
    const [sliderScreen, setSliderScreen] = useState(0)

    /*--------------------------------------REFS-----------------------------------*/
    const { showConfirmMsg } = useMessageBoxContext()

    /*--------------------------------------FUNCTIONS-----------------------------------*/

    const openForm = useCallback((record = {}) => {
        let lastRecordId = record.id
        if (global.isEmptyObject(record)) {
            lastRecordId = (array => array[array.length - 1])(Object.keys(value).filter(key => key < 0)) ?? 0
            lastRecordId -= 1
            record.isNewRecord = true
        } else {
            record.isNewRecord = false
        }
        console.log('isNewRecord', record.isNewRecord)


        onChangeRecord(record, lastRecordId)
        setInputStatus('normal')
        setSliderScreen(1)
    }, [onChangeRecord, value])

    const closeForm = useCallback(() => {
        setSliderScreen(0)
        onChangeRecord({})
    }, [onChangeRecord])

    const handleDelete = useCallback(record => {
        const callback = () => {
            const newValue = { ...value }
            delete newValue[record.id]
            record._actionType = 'delete'
            onChange(newValue, record)
        }

        showConfirmMsg({ title: deletingMessage, content: '', confirm: callback })
    }, [deletingMessage, onChange, showConfirmMsg, value])

    /*--------------------------------------IMPERATIVEHANDLE-----------------------------------*/
    const validate = useCallback(() => {
        const recordsLength = Object.keys(value).length
        let valid = true
        if (min !== undefined && recordsLength < min) valid = false
        if (max !== undefined && recordsLength > max) valid = false

        if (!valid) setInputStatus('error')
        return valid
    }, [value, min, max])

    useImperativeHandle(ref, () => ({
        validate, setStatus: setInputStatus, errorMessage,
        openForm, closeForm
    }), [validate, errorMessage, openForm, closeForm])

    /*--------------------------------------EFFECTS-----------------------------------*/

    /*--------------------------------------RENDER-----------------------------------*/
    const templateColumns = `repeat(${columns.length}, 1fr) auto`
    className = `${className} record_group record_group--status--${inputStatus}`

    const hideAddBtn = global.lengthObjectHelper(value) >= max

    return (
        <Flex maxWidth={maxWidth} style={style} tabIndex={0} className={className} >
            <Slider currentScreen={sliderScreen}>
                <RecordGroupList {...{ value, columns, templateColumns, handleDelete, openForm, hideAddBtn }} />
                <Grid className='record_group__form' gap='1em' padding='1.75rem 2.25rem'>
                    {formContent}
                </Grid>
            </Slider>
        </Flex>
    )
}

export default memo(forwardRef(RecordGroup), reactFastCompare)


