import React, { useCallback } from 'react'

import { Icon } from 'components'

import './checkbox.scss'

const CheckBox = (props) => {
    const {
        value,
        onChange,
    } = props

    let { className } = props

    const handleChange = useCallback(() => {
        onChange(!value)
    }, [onChange, value])

    return (
        <div className={`checkbox ${className}`} onClick={handleChange}>
            {value ?
                <Icon icon='Check_box-Mark' filter='active' />
                :
                <Icon icon='Check_box-Empty' filter='inactive' />
            }
        </div>
    )
}

export default CheckBox
