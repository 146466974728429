import { createContext, useCallback, useRef, useState } from "react"
import { useClickOutside } from 'hooks'


const ProfileMenuContext = createContext({isOpenProfileMenu: false})

export const ProfileMenuContextProvider = ({children}) => {
    const [isOpenProfileMenu, setIsOpenProfileMenu] = useState(false)
    const profileMenuButton = useRef()
    const profileMenu = useRef()
    // const [sideMenuSection, setSideMenuSection] = useState(false)
    const toggleProfileMenu = useCallback(() => {
        setIsOpenProfileMenu(isOpenProfileMenu => !isOpenProfileMenu)
    }, [])

    useClickOutside(profileMenu, (e) => {
        if(isOpenProfileMenu){
            if(profileMenuButton.current && !profileMenuButton.current.contains(e.target)){
                toggleProfileMenu()
            }
        }
    })
    
    return <ProfileMenuContext.Provider {...{value: {isOpenProfileMenu, toggleProfileMenu, profileMenuButton, profileMenu}, children}} />
}

export default ProfileMenuContext