import React, { useCallback, useState, useRef } from 'react'
import axios from 'axios';

import { Button, Grid, Text, MessageBox, Password } from 'components'
import './change_password.scss'
import { useDispatch } from 'react-redux';
import { logout } from 'redux/reducers/auth/authSlice';
import { mdAnimations } from 'scripts/generalVariables';

const ChangePassword = (props) => {
    const { setShowChangePassword } = props
    const [formData, setFormData] = useState({ currentPassword: '', newPassword: '', confirmedNewPassword: '' })
    const [error, setError] = useState('')
    const messageBoxRef = useRef()

    const dispatch = useDispatch()

    const handleChange = useCallback((value, name) => {
        setFormData(formData => ({ ...formData, [name]: value }))
    }, [])

    const handleSumbit = (e) => {
        e.preventDefault()
        let valid = false

        if ((formData.currentPassword !== '' && formData.newPassword !== '' && formData.confirmedNewPassword !== '') && (formData.newPassword === formData.confirmedNewPassword)) {
            valid = true
        }

        if (valid) {
            axios.put('/user/account/password', formData, { appSpinner: true })
                .then(() => {
                    messageBoxRef.current.show({
                        title: '¡Tu contraseña ha sido actualizada!',
                        content: 'Se cerrará la sesión',
                        animation: mdAnimations.SUCCESS,
                        buttons: <Button selfCenter onClick={logOut}>Entendido</Button>
                    })
                })
                .catch(err => {
                    setError(err.response.data.errors[0])
                })
        } else {
            if (formData.newPassword === '' && formData.confirmedNewPassword === '') {
                setError('Hay campos vacios')
            }
            if (formData.newPassword !== formData.confirmedNewPassword) {
                setError('Las contraseñas no coinciden')
            }
        }
    }

    const logOut = useCallback(() => {
        dispatch(logout())
    }, [dispatch])

    return (
        <Grid w100 className="change_password" gap="4.28em" >
            <MessageBox ref={messageBoxRef} />
            <form onSubmit={handleSumbit}>
                <Grid w100 className="change_password__form" gap="1.714em">
                    <Password label="Contraseña actual"
                        value={formData.currentPassword}
                        onChange={v => handleChange(v, 'currentPassword')}
                    />
                    <Password label="Nueva contraseña"
                        validate = {true}
                        value={formData.newPassword}
                        onChange={v => handleChange(v, 'newPassword')}
                    />
                    <Password label="Confirmar contraseña"
                        value={formData.confirmedNewPassword}
                        onChange={v => handleChange(v, 'confirmedNewPassword')}
                    />
                    {error !== '' && <Text size="2" color="error" align="center">{error}</Text>}
                    <Grid contentX="center" columns="auto auto" gap="1.142rem">
                        <Button maxWidth='7.858em' variant="outlined" onClick={() => { setShowChangePassword(false) }}>Cancelar</Button>
                        <Button type="submit" selfCenter>Guardar</Button>
                    </Grid>

                </Grid>
            </form>

        </Grid>
    )
}

export default ChangePassword
