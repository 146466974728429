
export const defaultBillingInfo = {
    business_name: 'Razon social',
    rfc: 'RFC',
    address: '',
    street_number: '',
    suite_number: '',
    suburb: '',
    postal_code: '',
    state_id: '',
    city_id: '',
    emails: '',
    tax_regime_id: '',
    cfdi_use_id: '',
    purchase_order: '',
}

export const billingInfoFields = (stateOptions = {}, cityOptions = {}, taxRegimeOptions = {}, cfdiUseOptions = {}) => ({
    business_name: {
        input: 'text_field',
        inputProps: {
            required: true,
            label: 'Razón social',
            errorMessage: 'Hay un error en el campo "Razón social"',
        }
    },
    rfc: {
        input: 'text_field',
        inputProps: {
            required: true,
            label: 'RFC',
            errorMessage: 'Hay un error en el campo "RFC"',
        }
    },
    address: {
        input: 'text_field',
        inputProps: {
            required: true,
            label: 'Calle',
            errorMessage: 'Hay un error en el campo "Calle"',
        }
    },
    street_number: {
        input: 'text_field',
        inputProps: {
            required: true,
            label: 'Número exterior',
            errorMessage: 'Hay un error en el campo "Número exterior"',
        }
    },
    suite_number: {
        input: 'text_field',
        inputProps: {
            label: 'Número interior (opcional)',
        }
    },
    suburb: {
        input: 'text_field',
        inputProps: {
            required: true,
            label: 'Colonia',
            errorMessage: 'Hay un error en el campo "Colonia"',
        }
    },
    postal_code: {
        input: 'text_field',
        inputProps: {
            required: true,
            type: 'number',
            label: 'Código postal',
            errorMessage: 'Hay un error en el campo "Código postal"',
            min: 0,
            onKeyPress: (event) => {
                if (event.target.value.length >= 5 || ['-', '+'].includes(event.key)) {
                    event.preventDefault()
                }
            },
        }
    },
    state_id: {
        input: 'combobox',
        inputProps: {
            required: true,
            label: 'Estado',
            errorMessage: 'Hay un error en el campo "Estado"',
            options: stateOptions,
        }
    },
    city_id: {
        input: 'combobox',
        inputProps: {
            required: true,
            label: 'Ciudad',
            errorMessage: 'Hay un error en el campo "Ciudad"',
            options: cityOptions,
        }
    },
    emails: {
        input: 'record_group',
        inputProps: {
            errorMessage: 'Hay un error en el campo "Correos electrónicos"',
            min: 1,
            max: 5,
            columns: [{ name: 'email', label: 'Correos electrónicos',/* helpText: 'Correos electrónicos'*/ }],
            fields: {
                email: {
                    input: 'text_field',
                    inputProps: {
                        label: 'Correo electrónico',
                        type: 'email',
                    },
                },
            },
        }
    },
    tax_regime_id: {
        input: 'combobox',
        inputProps: {
            required: true,
            label: 'Régimen fiscal',
            errorMessage: 'Hay un error en el campo "Régimen fiscal"',
            options: taxRegimeOptions,
        }
    },
    cfdi_use_id: {
        input: 'combobox',
        inputProps: {
            required: true,
            label: 'Uso de CFDI',
            errorMessage: 'Hay un error en el campo "Uso de CFDI"',
            options: cfdiUseOptions,
        }
    },
    purchase_order: {
        input: 'text_field',
        inputProps: {
            required: true,
            label: 'Orden de compra',
            errorMessage: 'Hay un error en el campo "Orden de compra"',
        }
    },
})
