import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import appThemeService from './appThemeService'

const initialState = {
    mainColor: '#24BD58',
    mainColorHover: '#39E600',
    secondaryColorHover: '#CDF8BF'
}

export const getAppTheme = createAsyncThunk('/route_get_theme_colors', async (_, thunkAPI) => {
    try {
        return await appThemeService.getAppTheme()
    } catch (error) {
        const errorMessage = (error.response && error.response.data && error.response.data.message)
            || error.message || error.toString()

        return thunkAPI.rejectWithValue(errorMessage)
    }
})

export const setAppTheme = createAsyncThunk('/company/theme/settings', async (themeColors, thunkAPI) => {
    try {
        return await appThemeService.setAppTheme(themeColors)
    } catch (error) {
        const errorMessage = (error.response && error.response.data && error.response.data.message)
            || error.message || error.toString()

        return thunkAPI.rejectWithValue(errorMessage)
    }
})

export const resetAppTheme = createAsyncThunk('/route_reset_theme_colors', async (themeColors, thunkAPI) => {
    try {
        return await appThemeService.resetAppTheme(themeColors)
    } catch (error) {
        const errorMessage = (error.response && error.response.data && error.response.data.message)
            || error.message || error.toString()

        return thunkAPI.rejectWithValue(errorMessage)
    }
})

const appSlice = createSlice({
    name: 'appTheme',
    initialState: { ...initialState, defaultColors: {...initialState}},
    reducers: {
        handleChangeColor: (state, { payload }) => {
            state[payload.color] = payload.value
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAppTheme.fulfilled, (state, { payload }) => {
                const { mainColor, mainColorHover, secondaryColorHover } = payload
                state.mainColor = mainColor
                state.mainColorHover = mainColorHover
                state.secondaryColorHover = secondaryColorHover
                global.setAppTheme(mainColor, mainColorHover, secondaryColorHover)
            })
            .addCase(setAppTheme.fulfilled, (state, { payload }) => {
                const { mainColor, mainColorHover, secondaryColorHover } = payload
                state.mainColor = mainColor
                state.mainColorHover = mainColorHover
                state.secondaryColorHover = secondaryColorHover
                global.setAppTheme(mainColor, mainColorHover, secondaryColorHover)
            })
            .addCase(setAppTheme.rejected, (state) => {
                const { mainColor, mainColorHover, secondaryColorHover } = state
                global.setAppTheme(mainColor, mainColorHover, secondaryColorHover)
            })
            .addCase(resetAppTheme.fulfilled, (state) => {
                global.resetAppTheme(initialState)
                state.mainColor = initialState.mainColor
                state.mainColorHover = initialState.mainColorHover
                state.secondaryColorHover = initialState.secondaryColorHover
            })
            .addCase(resetAppTheme.rejected, (state) => {
                global.resetAppTheme()
                state.mainColor = initialState.mainColor
                state.mainColorHover = initialState.mainColorHover
                state.secondaryColorHover = initialState.secondaryColorHover
            })
    }
})

export const { handleChangeColor } = appSlice.actions

export default appSlice.reducer