import React, { memo, useCallback, useEffect, useRef, useState, } from 'react'
import reactFastCompare from 'react-fast-compare'
//import { ... } from 'react-redux'
// import libraries
import _$ from 'jquery'

//import hooks
import { useClickOutside, useDebounced, useScrollOutside } from 'hooks'
//import customHooks
//import context/providers

import { TextField } from 'components'

//redux-actions

//scripts/js
//general_functions.js

//wrappers

//components-- e.g. stateless/layout components
import SearchBoxList from './SearchBoxList'

//global variables
//static values
import './search_box.scss'
import axios from 'axios'

const setListScroll = container => {
    const selectedElement = container.getElementsByClassName('search_box__option--selected')[0]
    if (selectedElement)
        setTimeout(() => {
            _$(container).find('.search_box__list').scrollTop(selectedElement.offsetTop)
        }, 50)
}

const SearchBox = (props) => {
    /*------------------------------------PROPS------------------------------*/
    let { className } = props
    const {
        placeholder = 'Buscar...',
        label,
        optionsEmptyText = 'No se encontraron resultados',
        onChange,
        selectedOption,
        listWidth,
        descWidth = '5em',
        searchUrl,
        searchData = {},
        onSearchSuccess,
        onSearchError
    } = props

    /*------------------------------------CONTEXT/STORE----------------------*/
    /*------------------------------------STATE------------------------------*/
    const [options, setOptions] = useState({})
    const [optionsDesc, setOptionsDesc] = useState({})
    const [isLoadingOptions, setIsLoadingOptions] = useState(false)
    const [searchText, setSearchText] = useState(!!selectedOption ? options[selectedOption] : '')
    const [isOpen, setIsOpen] = useState(false)

    /*------------------------------------REFS-------------------------------*/
    const containerRef = useRef()

    /*------------------------------------HOOKS------------------------------*/
    useClickOutside(containerRef, () => setIsOpen(false))
    useScrollOutside(containerRef, () => setIsOpen(false))

    /*------------------------------------FUNCTIONS--------------------------*/
    const getOptions = useDebounced(async value => {
        if (onChange) onChange('')
        setIsLoadingOptions(true)
        try {
            if (!searchUrl) throw new Error('searchUrl not provided')
            const response = await axios.get(searchUrl, {
                data: {
                    searchText: value,
                    ...searchData
                }
            })
            const { options, optionsDescriptions } = response.data
            setOptions(options)
            optionsDescriptions && setOptionsDesc(optionsDescriptions)
            onSearchSuccess && onSearchSuccess(response)
        } catch (error) {
            console.error(error)
            onSearchError && onSearchError(error)
        }
        setIsLoadingOptions(false)
    }, 600)

    const handleChangeInput = useCallback(value => {
        setSearchText(value)
        getOptions(value)
        setIsOpen(value !== '')
    }, [getOptions])

    const handleSelect = useCallback((value, text) => {
        setSearchText(text)
        setIsOpen(false)
        onChange && onChange(value)
    }, [onChange])

    const handleFocusInput = useCallback(() => {
        setSearchText('')
        if (!global.isEmptyObject(options)) setIsOpen(true)
    }, [options])

    const handleKeyDown = useCallback((e) => {
        if (!global.isEmptyObject(options))
            if (e.key === 'Enter')
                handleSelect(Object.keys(options)[0], Object.values(options)[0])
    }, [handleSelect, options])

    /*------------------------------------IMPERATIVEHANDLE-------------------*/
    /*------------------------------------EFFECTS----------------------------*/
    useEffect(() => {
        if (isOpen)
            setListScroll(containerRef.current)
    }, [isOpen])


    /*------------------------------------RENDER-----------------------------*/
    className = className ? `${className} search_box` : 'search_box'

    return (
        <div ref={containerRef} className={className}>
            <TextField className="search_box__input" label={label} placeholder={placeholder}
                value={searchText} onChange={handleChangeInput} onFocus={handleFocusInput} onKeyDown={handleKeyDown} />
            <SearchBoxList {...{
                isOpen, isLoadingOptions, options, optionsDescriptions: optionsDesc, selectedOption,
                handleSelect, containerRef, optionsEmptyText, listWidth, descWidth
            }} />
        </div>
    )
}

export default memo(SearchBox, reactFastCompare)