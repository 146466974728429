import React, { memo } from 'react'

import { Icon } from 'components'

import './icon_button.scss'

const IconButton = (props) => {
    /*---------------------------------------PROPS-----------------------------------------*/
    const {
        icon,
        className = '',
        size = '1.3',
        direction = 'top',
        onClick,
        style
    } = props
    /*---------------------------------------FUNCTIONS-----------------------------------------*/

    /*---------------------------------------RENDER-----------------------------------------*/

    return (
        <div style={style} className={`icon_button icon_button--${icon} ${className}`} onClick={onClick}>
            <Icon
                filter='active'
                icon={icon}
                size={size}
                direction={direction} />
        </div>
    )

}

export default memo(IconButton)