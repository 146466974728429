import regexDictionary from 'scripts/regexDictionary';

export const BusinessProfileFormObj = {
    businessName: {
        label: "Razón social",
        input: "textfield",
        message: (value) => "La razon social no puede quedar vacia.",
    },
    rfc: {
        label: "RFC",
        input: "textfield",
        message: (value) => "El RFC no puede quedar vacio y debe estar escrito en el formato correcto.",
        props: {
            pattern: regexDictionary.rfc
        }
    },
    geAdIndustryTypeId: {
        label: "Industria",
        input: "Combobox",
        message: (value) => "Debes seleccionar una industria.",
        props: {
            floatingLabel: true
        },
        
    },
    preponderantActivity: {
        label: "Actividad preponderante",
        input: "textfield",
        message: (value) => value ? "La actividad preponderante solo debe contener letras y no caracteres especiales." : "La actividad preponderante no puede quedar vacia.",
        props: {
            pattern: regexDictionary.spanishOnyLetters
        }
    },
    legalRepresentative: {
        label: "Representante legal",
        input: "textfield",
        message: (value) => value ? "El representate legal solo debe contener letras y no caracteres especiales." : "El representate legal no puede quedar vacio.",
        props: {
            pattern: regexDictionary.spanishOnyLetters
        }
    }
}

export const formKeys = Object.keys(BusinessProfileFormObj)