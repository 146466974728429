import { Fragment, useCallback, useEffect } from "react"
import { Navigate, Outlet, useBeforeUnload, useLocation } from "react-router-dom"
import { useSelector } from "react-redux"
import { useIdleTimerContext } from "react-idle-timer"
import useFetchingGlobalData from "../hooks/useFetchingGlobalData"

import { adminRoutes, noRedirectPaths, routes } from "./routes"
import { Portal } from "components"
import { IdleAppMask } from "Pages"

const RequireAuth = () => {

    /*---------------------------------------STATE-----------------------------------------*/
    const { app, auth } = useSelector(store => store)
    const { authenticated, user } = auth
    const { isAdminMode, isIdle } = app
    const { modePinActivated } = user.settingsUser
    const location = useLocation()
    const locationState = {}

    /*---------------------------------------HOOKS-----------------------------------------*/
    const fetchGlobalData = useFetchingGlobalData()
    const { getRemainingTime, pause, start } = useIdleTimerContext()

    useBeforeUnload(useCallback((e) => {
        const remainingTime = (getRemainingTime() > 1e4 && getRemainingTime()) || 1e4
        if (modePinActivated) {
            global.setLastAppInteraction(remainingTime)
        }
    }, [modePinActivated, getRemainingTime]))

    /*---------------------------------------EFFECT-----------------------------------------*/
    useEffect(() => {
        if (authenticated) {
            if (!isAdminMode) {
                fetchGlobalData()
            }
            if (modePinActivated && !isIdle) {
                setTimeout(start)
            } else if (!modePinActivated) {
                localStorage.removeItem('lastAppInteraction')
                pause()
            }
        }
    }, [authenticated, isAdminMode, isIdle, fetchGlobalData, modePinActivated, start, pause])

    /**
     * Redirect user to the /login page, saving current location to be redirected after sign in
     */
    if (!noRedirectPaths.includes(location.pathname)) {
        locationState.from = location
    }

    const loginPath = isAdminMode ? adminRoutes.login.path : routes.login.path

    return authenticated ?
        <Fragment>
            <Portal>
                <IdleAppMask />
            </Portal>
            <Outlet />
        </Fragment>
        :
        <Navigate replace to={loginPath} state={locationState} />
}

export default RequireAuth
