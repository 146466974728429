import React, { useCallback } from 'react'

import { Grid, IconButton, Text } from 'components'

const RecordGroupRecord = props => {
    const {
        templateColumns,
        columns,
        record,
        openForm,
        handleDelete,
    } = props

    const handleClickDelete = useCallback(e => {
        e.stopPropagation()
        handleDelete(record)
    }, [handleDelete, record])

    return (
        <Grid className='record_group__content__record' columns={templateColumns} itemsY='center' gap='0.3em' onClick={openForm}>
            {columns.map((column, i) =>
                <Text key={i} className='record_group__content__record__label'>
                    {column.format ? column.format(record[column.name], record) : record[column.name]}
                </Text>
            )}

            <IconButton icon='trash' size='1.25' onClick={handleClickDelete} />
        </Grid>
    )
}

export default RecordGroupRecord