import React from 'react'
import { matchPath, Outlet, useLocation } from 'react-router-dom'

import { Grid } from 'components'

import { noHeaderRoutes } from 'routing/routes'

import Header from './Header/Header'
import SideMenu from './SideMenu'

import './styles.scss'

const Layout = () => {
    const location = useLocation()
    const showHeader = !noHeaderRoutes.find(route => matchPath({ path: route }, location.pathname))
    return (
        <Grid className="app_container" rows={showHeader ? 'auto 1fr' : ''}>
            {showHeader && <Header />}
            <Grid className="layout_content" columns={showHeader ? 'auto' : ''}>
                {showHeader && <SideMenu />}
                <Outlet />
            </Grid>
        </Grid>
    )
}

export default Layout
