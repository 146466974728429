import React, { Fragment, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Button, Flex, Grid, Text } from 'components'

import TokenSellingModal from './TokenSellingModal/TokenSellingModal'
import TokenEditModal from './TokenEditModal/TokenEditModal'

const defaultModule = { id: 0, moduleType: 'TOKEN', name: 'TOKENS PARA CURSOS', tokens: 0 }

const modalType = { TOKEN_EDIT: 'TOKEN_EDIT', TOKEN_SELLING: 'TOKEN_SELLING', }

const CompanyTokenInfo = () => {
    /*------------------------------------STATE--------------------------------*/
    const location = useLocation()
    const { moduleData: locationStateModuleData } = location.state || defaultModule

    const [moduleData, setModuleData] = useState(locationStateModuleData)
    const [openModal, setOpenModal] = useState('')

    /*------------------------------------HOOKS--------------------------------*/
    const navigate = useNavigate()

    /*------------------------------------FUNCTIONS--------------------------------*/
    const closeModal = () => {
        setOpenModal('')
    }

    /*------------------------------------EFFECT--------------------------------*/

    /*------------------------------------RENDER--------------------------------*/

    return (
        <Fragment>
            <Button alignSelf='start' onClick={() => navigate(-1)}>Regresar</Button>
            <Flex gap='2rem' direction='column' className='company_info__token_info_page' align='center'>
                <Text bold size={7} align='center'>{moduleData.name}</Text>

                <Grid gap='1rem'>
                    <Text align='center'><b>Tokens:</b> {moduleData.tokens}</Text>
                    <Grid gap='1rem' direction='column' itemsX='center'>
                        <Button onClick={() => setOpenModal(modalType.TOKEN_EDIT)}>Editar tokens</Button>
                        <Button onClick={() => setOpenModal(modalType.TOKEN_SELLING)}>Venta</Button>
                    </Grid>
                </Grid>
            </Flex>
            {openModal === modalType.TOKEN_EDIT &&
                <TokenEditModal
                    closeModal={closeModal}
                    moduleId={moduleData.id}
                    setModuleData={setModuleData}
                />
            }
            {openModal === modalType.TOKEN_SELLING &&
                <TokenSellingModal
                    closeModal={closeModal}
                    moduleId={moduleData.id}
                    setModuleData={setModuleData}
                />
            }
        </Fragment>
    )
}

export default CompanyTokenInfo
