import React, { useCallback, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import regexDictionary from 'scripts/regexDictionary'

import { Button, CheckBox, ComboBox, Divisor, Flex, FormHandler, Grid, Link, RecordGroup, SearchBox, Text, TextField } from 'components'
import { useMessageBoxContext } from 'hooks'
import TestComponent from './TestComponent'

const comboboxOptionsMock = {
    1: "Alberto",
    2: "Jose",
    3: "Mauricio",
    4: "Mario",
}

const formFields = {
    user: {
        input: 'combobox',
        inputProps: {
            options: comboboxOptionsMock,
            label: 'Usuario',
            required: true,
            floatingLabel: true,
            nullValue: true,
            errorMessage: 'El campo "Usuario" es requerido'
        }
    },
    name: {
        input: 'text_field',
        inputProps: {
            label: 'Nombre',
            required: true,
            pattern: regexDictionary.onlyLetters,
            errorMessage: 'El campo "Nombre" es requerido y solo permite letras'
        }
    },
    label: 'Genero',
    gender: {
        input: 'toggle',
        inputProps: {
            label1: 'Masculino',
            label2: 'Femenino',
            notInactive: true,
        }
    }
}

const recordGroupMock = { 1: { name: 'Finanzas', user: '3', gender: 1 } }

const TestingPage = () => {
    /*---------------------------------------STATE-----------------------------------------*/
    const [formData, setFormData] = useState({})
    const [recordGroupValue, setRecordGroupValue] = useState(recordGroupMock)
    const [comboboxValue, setComboboxValue] = useState('')
    const [textFieldValue, setTextFieldValue] = useState('')
    const [checkbox, setCheckbox] = useState(false)

    /*---------------------------------------REF-----------------------------------------*/
    const formHandlerRef = useRef()
    const textFieldRef = useRef()

    /*---------------------------------------HOOKS-----------------------------------------*/
    const { showConfirmMsg } = useMessageBoxContext()

    /*---------------------------------------FUNCTIONS-----------------------------------------*/

    const makeRequest = (processingType = 'appProcessing', catchNetworkError = false, saveProcessing = false) => {
        let endpoint = process.env.REACT_APP_API_ENDPOINT.substring(0, process.env.REACT_APP_API_ENDPOINT.indexOf('v1'))
        if (catchNetworkError) endpoint += 'users'
        axios.get(endpoint, { [processingType]: true, catchNetworkError, saveProcessing })
    }

    const saveForm = () => {
        if (formHandlerRef.current.validate()) {
            makeRequest('appProcessing', false, true)
        }
    }

    const handleChangeRecordGroup = useCallback((value, record, c) => {
        setRecordGroupValue(value)
    }, [])

    /*---------------------------------------EFFECTS-----------------------------------------*/
    useEffect(() => {
    }, [])

    /*---------------------------------------RENDER-----------------------------------------*/

    return (
        <Grid className="testing_page" padding="2em" gap="2em" itemsX="center" contentY="start">
            <Link router bold href="/home/register" size='4'>Link component with routing</Link>

            <Divisor thickness='2px' />

            <Text size='6'>Request types</Text>
            <Flex gap='1rem'>
                <Button onClick={() => makeRequest('appProcessing', true)}>Request catching error</Button>
                <Button variant='outlined' onClick={() => makeRequest('appProcessing', false, true)}>Request showing save</Button>
                <Button onClick={() => makeRequest('appProcessing')}>Request showing processing</Button>
                <Button variant='outlined' onClick={() => makeRequest('appSpinner')}>Request showing spinner</Button>
            </Flex>

            <Text size='6'>Confirm Message</Text>
            <Button onClick={() => showConfirmMsg({
                confirm: () => { makeRequest('appProcessing', false, true) },
                cancel: () => { alert('Se canceló la acción') }
            })}>Show confirm message</Button>

            <Divisor thickness='2px' />
            <Text size='6'>UseRecordGroup hook</Text>
            <TestComponent />

            <Divisor thickness='2px' />

            <Text size='6'>FormHandler</Text>
            <Grid gap='2rem' contentY='center' contentX='center'>
                <FormHandler ref={formHandlerRef} fields={formFields} formData={formData} setFormData={setFormData} />
                <Button onClick={saveForm}>Save</Button>
            </Grid>

            <Divisor thickness='2px' />

            <Text size='6'>RecordGroup</Text>
            <RecordGroup
                maxWidth='320px'
                // onChangeFormData={(a, b, c, d) => console.log(a, b, c, d)}
                identifier={['user', (value) => `El usuario "${comboboxOptionsMock[value]}" ya fue seleccionado.`]}
                templateColumns='1fr'
                formTitle='Registro'
                value={recordGroupValue}
                onChange={handleChangeRecordGroup}
                fields={formFields}
                columns={[{ name: 'name', label: 'Nombre' }]}
                deletingMessage='¿Estás seguro que quieres borrar este registro?'
                errorMessage=''
                min='2'
                max='5'
            />
            <Text size='6'>ComboBox</Text>
            <ComboBox floatingLabel w100 nullValue
                label='ComboBox'
                maxWidth='20em'
                value={comboboxValue}
                onChange={setComboboxValue}
                options={comboboxOptionsMock} />
            <Text size='6'>SearchBox</Text>
            <SearchBox
                placeholder='Buscar...'
                label='SearchBox'
                optionsEmptyText='No se encontraron resultados'
                onChange={console.log}
                descWidth='5em'
            />
            <Text size='6'>TextField</Text>
            <TextField
                ref={textFieldRef}
                label='Only letters'
                value={textFieldValue}
                onChange={setTextFieldValue}
                pattern={regexDictionary.onlyLetters} />
            <Button onClick={() => { textFieldRef.current.validate() }}>Validate only letters</Button>
            <Text size='6'>CheckBox</Text>
            <CheckBox value={checkbox} onChange={setCheckbox} />
        </Grid>
    )
}

export default TestingPage