import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { FilterTable, Grid, Text } from 'components'
import axios from 'axios'

const companyColumns = [{ name: 'businessName', displayName: 'Razon social' }]

const AdminHome = () => {
    /*------------------------------------STATE--------------------------------*/
    const [companies, setCompanies] = useState([])
    const [isLoadingCompanies, setIsLoadingCompanies] = useState(false)

    /*------------------------------------REF--------------------------------*/
    const companiesRef = useRef([])

    /*------------------------------------HOOKS--------------------------------*/
    const navigate = useNavigate()

    /*------------------------------------FUNCTIONS--------------------------------*/
    const getCompanies = async () => {
        setIsLoadingCompanies(true)
        try {
            const response = await axios.get('/admin/companies/all')
            if (response.data) {
                setCompanies(response.data.response)
                companiesRef.current = response.data.response
            }
        } catch (error) {
            console.error('GET_COMPANIES_ERROR')
        }
        setIsLoadingCompanies(false)
    }

    const onChangeFilter = (filter) => {
        let filteredCompanies = [...companiesRef.current]
        if (filter !== '') {
            filteredCompanies = filteredCompanies.filter(c => (c.businessName.toUpperCase().search(RegExp(`${filter.toUpperCase()}`)) >= 0))
        }
        setCompanies(filteredCompanies)
    }

    const onClickRow = (row) => {
        navigate(`/admin/company_info/${row.companyId}`)
    }

    /*------------------------------------EFFECT--------------------------------*/
    useEffect(() => {
        setTimeout(() => { getCompanies() }, 0)
        // run effect once
        // eslint-disable-next-line
    }, [])

    /*------------------------------------RENDER--------------------------------*/

    return (
        <Grid gap='1rem' className='admin_accounts_page'>
            <Text bold align='center' size={7}>Cuentas</Text>
            <FilterTable
                columns={companyColumns}
                rows={companies}
                isLoadingRows={isLoadingCompanies}
                onChangeFilter={onChangeFilter}
                onClickRow={onClickRow}
            />
        </Grid>
    )
}

export default AdminHome