import React, { memo, forwardRef, } from 'react'
import {LottieAnimationPlayer} from 'components'

import './lottie_animation_button.scss'

export default memo ( forwardRef( function LottieAnimationButton (props, ref) {
    const {refButton, refAnimation} = ref
    
    let {
        className = '',
        onClick = () => {},
        onMouseEnter = () => {},
        onMouseLeave = () => {},
        animation = '', 
        style = {}, 
        defaultSegment = 1,
        h100 = false,
        w100 = false,
        reset = false,
    } = props
    
    /*-------------------------------STYLE--------------------------------*/
        className += ' animation_button flex--wrap'
        if(h100) style['height'] = '100%'
        if(w100) style['width'] = '100%'

    /*-------------------------------RENDER-------------------------------*/
        return (
            <button {...{className, onMouseEnter, onMouseLeave, onClick, style, ref: refButton}}>
                <LottieAnimationPlayer {...{
                    animation,
                    style: {
                        width: '100%',
                        height: '100%',
                    },
                    defaultSegment,
                    ref: refAnimation, reset,
                }}/>
            </button> 
        )
}))