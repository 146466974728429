import React, { forwardRef, memo, useCallback, useImperativeHandle, useRef, useState } from 'react'
import reactFastCompare from 'react-fast-compare'

import { useMessageBoxContext } from 'hooks'

import { Button, Flex, Slider } from 'components'

import RecordGroupList from './RecordGroupList'
import RecordGroupForm from './RecordGroupForm'

import './record_group.scss'

const RecordGroup = (props, ref) => {
    /*--------------------------------------PROPS-----------------------------------*/
    const {
        formTitle = 'Registro',
        columns = [], //[{name:'Display name', helpText:'Help Text'}]
        fields = {},
        value = {},
        onChange, // function(newState, {...modifiedRecord, type: 'add'/'delete'})
        onChangeFormData,
        onValidateForm,
        min,
        max,
        identifier,
        deletingMessage = '¿Estás seguro que quieres borrar este registro?',
        errorMessage = '',
        maxWidth,
        style,
    } = props

    let { className = '' } = props

    /*--------------------------------------STATE-----------------------------------*/
    const [formData, setFormData] = useState({})
    const [inputStatus, setInputStatus] = useState('normal')
    const [sliderScreen, setSliderScreen] = useState(0)

    /*--------------------------------------REFS-----------------------------------*/
    const { showMB, hideMB, showConfirmMsg } = useMessageBoxContext()
    const formRef = useRef()

    /*--------------------------------------FUNCTIONS-----------------------------------*/

    const openForm = useCallback((record = {}) => {
        if (global.isEmptyObject(record)) {
            const lastRecordId = (array => array[array.length - 1])(Object.keys(value).filter(key => key < 0)) ?? 0
            record = {
                ...global.setStateValueHandler(fields),
                id: lastRecordId - 1
            }
        }

        setFormData(record)
        setInputStatus('normal')
        setSliderScreen(1)
    }, [value, fields])

    const closeForm = useCallback(() => {
        setSliderScreen(0)
        setFormData({})
    }, [])

    const validateNewRecord = useCallback(record => {
        if (identifier) {
            const [fieldName, identifierText] = identifier
            for (const key in value) {
                if (key !== record.id && value[key][fieldName] === record[fieldName])
                    return showMB({
                        content: typeof identifierText === 'function' ?
                            identifierText(record[fieldName])
                            :
                            `El ${identifierText} "${record[fieldName]}" ya esta registrado.`,
                        buttons: <Button onClick={hideMB}>Entendido</Button>
                    })
            }
        }

        return true
    }, [hideMB, identifier, showMB, value])

    const handleSave = useCallback(record => {
        if (!validateNewRecord(record)) return

        record._actionType = record.id < 0 ? 'create' : 'update'
        onChange({ ...value, [record.id]: record }, record)
        closeForm()
    }, [value, onChange, closeForm, validateNewRecord])

    const handleDelete = useCallback(record => {
        const callback = () => {
            const newValue = { ...value }
            delete newValue[record.id]
            record._actionType = 'delete'
            onChange(newValue, record)
        }

        showConfirmMsg({ title: deletingMessage, content: '', confirm: callback })
    }, [deletingMessage, onChange, showConfirmMsg, value])

    /*--------------------------------------IMPERATIVEHANDLE-----------------------------------*/
    const validate = useCallback(() => {
        const recordsLength = Object.keys(value).length
        let valid = true
        if (min !== undefined && recordsLength < min) valid = false
        if (max !== undefined && recordsLength > max) valid = false

        if (!valid) setInputStatus('error')
        return valid
    }, [value, min, max])

    useImperativeHandle(ref, () => ({
        validate, setStatus: setInputStatus, errorMessage,
        openForm, closeForm, formData, setFormData
    }), [validate, errorMessage, openForm, formData, closeForm])

    /*--------------------------------------EFFECTS-----------------------------------*/

    /*--------------------------------------RENDER-----------------------------------*/
    const templateColumns = `repeat(${columns.length}, 1fr) auto`
    className = `${className} record_group record_group--status--${inputStatus}`

    const hideAddBtn = global.lengthObjectHelper(value) >= max

    return (
        <Flex maxWidth={maxWidth} style={style} tabIndex={0} className={className} >
            <Slider currentScreen={sliderScreen}>
                <RecordGroupList {...{ value, columns, templateColumns, handleDelete, openForm, hideAddBtn }} />
                <RecordGroupForm {...{
                    formData, setFormData, formRef, fields, formTitle, closeForm,
                    setSliderScreen, handleSave, onValidateForm,
                    onChangeFormData
                }} />
            </Slider>
        </Flex>
    )
}

export default memo(forwardRef(RecordGroup), reactFastCompare)