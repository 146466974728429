import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Grid, TextField, Text, CharacterField, MessageBox, Password } from 'components'
import { useSearchParams } from 'react-router-dom'
import axios from "axios"
import './recover_password.scss'
import { mdAnimations } from 'scripts/generalVariables'

const RecoverPassword = (props) => {
    const { handleCurrentScreen } = props

    const [recoveredPassword, setRecoveredPassword] = useState(false)
    const [error, setError] = useState('')
    const [email, setEmail] = useState('')
    const [errorCode, setErrorCode] = useState('')
    const [validCode, setValidCode] = useState(false)
    const [code, setCode] = useState('')

    const [newPasswordForm, setNewPasswordForm] = useState({password: '', passwordConfirmation: ''})

    const [queryParameters] = useSearchParams()
    const recoverCodeLength = 6
    
    /*-------------------------------REF---------------------------------------------------------------*/
    const characterFieldRef = useRef()
    const messageBoxRef = useRef()
    const emailInputRef = useRef();
    
    const sendEmail = async () => {
        if (email !== '') {
            const response = await axios.post("/user/account/recover-password", { email }, { appProcessing: true })
                .catch((err) => { 
                    setError(err.response.data.errors[0]) 
                    emailInputRef.current.setStatus("error")
                }
                
                )
            if (response.data && !response.data.error) {
                setRecoveredPassword(true)
            }
        } else {
            setError('Hay campos vacios')
        }
    }
    const sendEmailOnRecover = async () => {
        if (email !== '') {
            const response = await axios.post("/user/account/recover-password", { email }, { appProcessing: true })
                .then((res) => {
                    messageBoxRef.current.show({
                        title: <Text size={4} bold color='seventh'>Correo enviado</Text>,
                        animation: mdAnimations.SUCCESS,
                        content: <Text align="center">Revisa tu bandeja de entrada.</Text>,
                        buttons: <Button onClick={() => { messageBoxRef.current.hide() }}>Entendido</Button>
                    })
                }).catch((err) => { setError(err.response.data.errors[0]) })
            if (response.data && !response.data.error) {
                setRecoveredPassword(true)
            }
        } else {
            setError('Hay campos vacios')
        }
    }

    const handleRecoverCode = (v) => {
        if (v.length === recoverCodeLength) {
            axios.post('/user/account/verify-recovery-code', {
                "code": v.join('')
            }, { appSpinner: true }).then(response => {
                characterFieldRef.current.setStatus("normal")
                setCode(v.join(''))
                setErrorCode('')
                setValidCode(true)
            }).catch((err) => {
                characterFieldRef.current.setStatus("error")
                setErrorCode(err.response.data.errors[0])
                setValidCode(false)
            })
        }
    }

    const handleNewPassword = useCallback((name, value) => {
        setNewPasswordForm(newPasswordForm => ({ ...newPasswordForm, [name]: value }))
    }, [])

    const clearState = useCallback(()=> {
        //Clear state
        setRecoveredPassword(false)
        setEmail('')
        setErrorCode('')
        setValidCode(false)
        setCode('')
        setNewPasswordForm({password: '', passwordConfirmation: ''})    
    }, [])

    const saveNewPassword = () => {
        axios.post('/user/account/set-new-password', {
            "code": code,
            ...newPasswordForm
        }, { appProcessing: true }).then(response => {
            clearState();
            messageBoxRef.current.show({
                title: <Text size={4} bold color="seventh" align="center">¡Tu contraseña ha sido actualizada!</Text>,
                content: <Text align="center">Ya puedes Iniciar sesión con tus credenciales</Text>,
                animation: mdAnimations.SUCCESS,
                buttons: <Button onClick={() => { handleCurrentScreen(0); messageBoxRef.current.hide() }}>Iniciar Sesion</Button>
            })
        }).catch((err) => {
            setErrorCode(err.response.data.errors[0])
        })
    }

    useEffect(() => {
        if (queryParameters.get("code")) {
            setCode(queryParameters.get("code"))
            setRecoveredPassword(true)
            axios.post('/user/account/verify_code_on_charge', {
                "code": queryParameters.get("code")
            }, { appSpinner: true }).then(response => {
                if (response.data.response.error === false && response.data.response.response === "CODE_ACCEPTED") {
                    setEmail(response.data.response.email)
                    //setExpired(false)
                }
                else if (response.data.response.error === true && response.data.response.response === "CODE_EXPIRED") {
                    setEmail(response.data.response.email)
                    //setExpired(false)
                }
            }).catch((err) => {
                messageBoxRef.current.show({
                    content: <Text align="center">El enlace que intentas usar ha expirado. Recupera tu contraseña nuevamente.</Text>,
                    buttons: <Button onClick={() => { setRecoveredPassword(false); messageBoxRef.current.hide() }}>Entendido</Button>
                })
            })
        }
        // eslint-disable-next-line
    }, [])

    return (
        <Grid contentX="center" contentY="center">
            <MessageBox ref={messageBoxRef} />
            <Grid w100 className="recover_password" maxWidth="25.25em">
                {!recoveredPassword ?
                    <Grid gap="1.71em">
                        <Text bold size="4" align="center">Recupera tu contraseña</Text>
                        <Text regular align="center">Escribe el correo electrónico asociado a tu cuenta y enviaremos información para recuperar tu contraseña.</Text>
                        <TextField label="Correo electrónico"
                            type="email"
                            value={email}
                            ref = {emailInputRef}
                            onChange={v => setEmail(v)}
                        />
                        {error !== '' &&
                            <Text size="2" color="error" align="center">{error}</Text>
                        }
                        <Button selfCenter onClick={() => { sendEmail() }}>Recuperar</Button>
                        <Button variant="outlined" selfCenter onClick={() => { handleCurrentScreen(0); clearState(); }}>Regresar</Button>
                    </Grid>
                    :
                    <Grid gap="1.71em" itemsX="center">
                        <Text bold size="4" align="center">Ingresa el código enviado a tu correo electrónico</Text>
                        <Grid gap="0.85rem">
                            <CharacterField onChange={handleRecoverCode} ref={characterFieldRef} length={recoverCodeLength} />
                            {errorCode &&
                                <Text size="2" align="center" color="error">{errorCode}</Text>
                            }
                        </Grid>
                        {validCode ?
                            <Grid w100 gap="1.71em">
                                <Password 
                                    label="Nueva contraseña"
                                    value={newPasswordForm.password}
                                    onChange={(v) => {handleNewPassword("password", v)}}
                                    validate = {true}
                                />
                                <Password
                                    label="Confirmar nueva contraseña"
                                    value={newPasswordForm.passwordConfirmation}
                                    onChange={(v) => {handleNewPassword("passwordConfirmation", v)}}
                                />
                                <Button selfCenter onClick={() => { saveNewPassword() }}>Actualizar contraseña</Button>
                            </Grid>
                            :
                            <Button  onClick={() => { sendEmailOnRecover(); }}>Enviar código de nuevo</Button>
                        }
                        <Button selfCenter variant="outlined" onClick={() => { setRecoveredPassword(false) }} >Regresar</Button>
                        {/* <Text medium align="center">Se han enviado instrucciones para recuperar la contraseña a tu correo electrónico.</Text>
                        <Button selfCenter onClick={() => { setRecoverPassword(false) }}>Entendido</Button> */}
                    </Grid>
                }
            </Grid>
        </Grid>
    )
}

export default RecoverPassword