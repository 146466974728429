import React from 'react'
import { useLocation } from 'react-router-dom'
import { routes } from 'routing/routes'
import { Grid, IconButton, Link, RouterLink, Text } from 'components'

import './breadcrumb.scss'

const Breadcrumb = () =>{
    const location = useLocation()
    const currentLocation = location.pathname.split('/').filter(crumb => crumb !== '')

    let crumbLink = ''
    
    return(
        <Grid columns="auto" direction="column" gap="0.71em">
            {
                currentLocation.map((route, i) => {
                    crumbLink += `/${route}`
                    return(
                        <Grid key={i} style={{marginLeft: '5px'}} columns="auto" direction="column" gap="0.71em">
                                {routes[route]?.hasOwnProperty('breadcrumbIcon') ?
                                    <RouterLink to={crumbLink}>
                                        <IconButton filter='active' icon={routes[route].breadcrumbIcon} size="1.5"/>
                                    </RouterLink>
                                :
                                    <Link router regular to={crumbLink} size="2">
                                        {routes[route].breadcrumbName}
                                    </Link>
                                }
                            {currentLocation.length !== i+1 &&
                                <Text medium size="2">/</Text>
                            }
                        </Grid>
                        
                    )
                })
            }
        </Grid>
    )
}

export default Breadcrumb