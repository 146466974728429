import React from 'react'
import { Grid } from 'components'


export default function Branches(props) {
    
    return(
        <Grid>Branchessssss</Grid>
    )
}
