import axios from "axios"

import { generateFilter } from "./cssFilterGenerator"
import { isAdminSubdomain } from "./generalVariables"

global.getInputDefaultValue = input => {
    switch (input) {
        case 'checkbox': return false
        case 'record_group_checkbox': return { checks: [], others: {} }
        case 'checkbox_group': return []
        case 'combobox':
        case 'number_field':
        case 'text_field': return ''
        case 'record_group':
        case 'record_group_v2':
        case 'file_input': return {}
        case 'calendar':
        case 'calendar_v2': return null
        case 'date_month_year': return { month: '', year: '' }
        case 'toggle':
        case 'toggle_switch': return false
        default: return ''
    }
}

global.lengthObjectHelper = (object) => Object.keys(object).length

global.mapObjectHelper = (object, callback) => {
    return Object.keys(object).map((key, index) => {
        const value = object[key]
        return callback(value, key, index)
    })
}

global.setStateValueHandler = fields => {
    const stateValueObject = {}
    Object.keys(fields).filter(key => !key.includes('label')).forEach(key => { stateValueObject[key] = global.getInputDefaultValue(fields[key].input) })
    return stateValueObject
}

global.setAxiosAuthToken = (token) => {
    localStorage.setItem('token', token)
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

global.setSession = (user, token) => {
    localStorage.setItem('user', JSON.stringify(user))
    global.setAxiosAuthToken(token)
}

global.clearSession = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.removeItem('company')
    localStorage.removeItem('isIdle')
    localStorage.removeItem('lastAppInteraction')
    localStorage.removeItem('darkMode')
    document.querySelector('.app_container')?.classList.remove('is_idle')
    document.body.classList.remove('dark', false)
    global.resetAppTheme()
}

global.redirectTo = (path) => {
    window.location.href = window.location.href.split('#')[0] + `#${path}`
}

global.setCookieOnce = (name, value) => {
    document.cookie = `${name}=${value}; expires=Fri 31 Dec 9999 23:59:59 GMT; secure; SameSite=none";`
}

global.getCookieToken = () =>
    document.cookie.replace(/(?:(?:^|.*;\s*)x-access-token\s*=\s*([^;]*).*$)|^.*$/, "$1")

global.switchMDAnimation = value => {
    switch (value) {
        case 1: return 'MD-Exito'
        case 2: return 'MD-Error'
        case 3: return 'MD-Alerta'
        case 4: return 'MD-Informativo'
        default: return 'MD-Exito'
    }
}

global.addMsToDate = (date, milliseconds) => {
    date.setMilliseconds(date.getMilliseconds() + milliseconds)
    return date
}

global.setLastAppInteraction = (ms) => {
    localStorage.lastAppInteraction = global.addMsToDate(new Date(), ms)
}

// global.getCookieValue = (name) =>
//     document.cookie.replace(new RegExp('/(?:(?:^|.*;\s*)' + name + '\s*\=\s*([^;]*).*$)|^.*$/'), "$1")

global.initializeAppTitle = () => {
    if (isAdminSubdomain)
        document.title = 'MaGiEi Admin'
}

global.initializeAppColorCustomization = () => {
    const inputsColor = localStorage.getItem('inputs-color')
    const buttonsColor = localStorage.getItem('buttons-color')
    if (inputsColor !== null) {
        document.querySelector(':root').style.setProperty('--input--border-color--primary', inputsColor)
    }
    if (buttonsColor !== null) {
        document.querySelector(':root').style.setProperty('--button--border-color--primary', buttonsColor)
        document.querySelector(':root').style.setProperty('--button--bg-color--primary', buttonsColor)
    }

    if (JSON.parse(localStorage.getItem('darkMode')))
        document.body.classList.toggle('dark', true)
}

global.setCustomColors = (color, type, storageName) => {
    switch (type) {
        case 'INPUTS':
            localStorage.setItem(storageName, color)
            document.querySelector(':root').style.setProperty('--input--border-color--primary', color)
            break
        case 'BUTTONS':
            localStorage.setItem(storageName, color)
            document.querySelector(':root').style.setProperty('--button--border-color--primary', color)
            document.querySelector(':root').style.setProperty('--button--bg-color--primary', color)
            break
        default: break
    }
}

global.isEmptyObject = (object) => Object.keys(object).length === 0

global.generateFilter = generateFilter

global.resetAppTheme = (initialState) => {
    localStorage.setItem('theme', JSON.stringify(initialState))

    const container = document.body
    container.style.removeProperty('--component--color')
    container.style.removeProperty('--component--color--active')
    container.style.removeProperty('--component--option--bg-color--hover')
    container.style.removeProperty('--filter--active')
}

global.setAppTheme = (mainColor, mainColorHover, secondaryColorHover) => {
    localStorage.setItem('theme', JSON.stringify({ mainColor, mainColorHover, secondaryColorHover }))

    const container = document.body
    container.style.setProperty('--component--color', mainColor)
    container.style.setProperty('--component--color--active', mainColorHover)
    container.style.setProperty('--component--option--bg-color--hover', secondaryColorHover)
    container.style.setProperty('--filter--active', global.generateFilter(mainColor))
}

global.parseCurrency = (number) => {
    return Number(number).toLocaleString('es-MX', {
        style: 'currency',
        currency: 'MXN'
    })
}

global.sortByNumber = (a, b, sortColumn, ascSort) => {
    if (Number(a[sortColumn]) < Number(b[sortColumn])) return ascSort ? -1 : 1
    if (Number(a[sortColumn]) > Number(b[sortColumn])) return ascSort ? 1 : -1
    return 0
}

global.arrayToObjectOptions = (array, idKey, nameKey) => {
    const result = {}
    array.forEach(element => {
        result[element[idKey]] = element[nameKey]
    })
    return result
}

global.getCurrentPath = (newPath = '', level = -1) => window.location.pathname.split('/').slice(0, level).join('/') + newPath