import axios from "axios"

const getAppTheme = async () => {
    const response = await axios.get('/company/theme/settings', { something: 'asdsad' }, { appProcessing: true })
    return response.data
}

const setAppTheme = async (userData) => {
    const response = await axios.post('/company/theme/settings', userData, { appProcessing: true })
    return response.data
}

const resetAppTheme = async (userData) => {
    const response = await axios.put('/company/theme/reset/settings', userData, { appProcessing: true })
    return response
}

const appThemeFunctions = { getAppTheme, setAppTheme, resetAppTheme }

export default appThemeFunctions