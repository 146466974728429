import React from 'react'

import { Grid, Icon, Text } from 'components'

import './fake_combobox.scss'

const FakeComboBox = () => {
    return (
        <Grid className='label_up combobox--focused combobox--status--normal combobox__container fake_combobox' >
            <label className='combobox__label'>Aquí seleccionas una opción</label>
            <Grid className='combobox' tabIndex='0'
                contentY="center" itemsY='center' direction='column' gap='1em' columns='1fr auto'>
                <Text className='combobox__text'>Seleccionar</Text>
                <Icon icon='arrow' className='combobox__arrow' direction="right" size='1' />
                <fieldset className='combobox__fieldset'>
                    <legend className='combobox__legend'>Aquí seleccionas una opción</legend>
                </fieldset>
            </Grid>

            <ul className='combobox__list' style={{ position: 'relative', boxShadow: 'none', border: '1px solid var(--color--white--2)' }}>
                <li className='combobox__list__option'>Opción sin seleccionar</li>
                <li className='combobox__list__option combobox__list__option--selected'>Opción seleccionada</li>
                <li className='combobox__list__option' style={{ backgroundColor: 'var(--component--option--bg-color--hover)' }}>Opción con ratón encima</li>
            </ul>
        </Grid>
    )
}

export default FakeComboBox