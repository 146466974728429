import React from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import { Flex, Grid, Icon, Link, Text } from 'components'

import './admin_side_menu.scss'

import { adminRoutes } from 'routing/routes'

const AdminSideMenu = () => {
    const location = useLocation()

    return (
        <Grid className='admin__side_menu' contentY='start'>
            <Link router href={adminRoutes.home.path}>
                <Flex direction='column' align='center' justify='space-around' className={`side_menu__section${matchPath({ path: adminRoutes.home.path }, location.pathname) ? ' active' : ''}`}>
                    <Icon icon='calculadora' filter='gray--2' size={2} />
                    <Text>Cuentas</Text>
                </Flex>
            </Link>
            <Link router href={adminRoutes.invoices.path}>
                <Flex direction='column' align='center' justify='space-around' className={`side_menu__section${matchPath({ path: adminRoutes.invoices.path }, location.pathname) ? ' active' : ''}`}>
                    <Icon icon='calculadora' filter='gray--2' size={2} />
                    <Text>Facturas</Text>
                </Flex>
            </Link>
            <Link router href={adminRoutes.support.path}>
                <Flex direction='column' align='center' justify='space-around' className={`side_menu__section${matchPath({ path: adminRoutes.support.path }, location.pathname) ? ' active' : ''}`}>
                    <Icon icon='icono_usuario' filter='gray--2' size={2} />
                    <Text>Soporte</Text>
                </Flex>
            </Link>
        </Grid>
    )
}

export default AdminSideMenu
