import React, { Fragment, useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'

import { Flex, Grid, Link, Text } from 'components'

import { adminRoutes } from 'routing/routes'
import { planOptions } from '../fieldsOptions'

import './admin_company_info.scss'

const sumBranchesHeadCount = branches => branches.reduce((accumulator, branch) => accumulator + branch.headCount, 0)

const defaultCompany = {
    companyId: 0, businessName: '', industryType: '', branches: [], owners: [], modules: [],
}

const AdminCompanyInfo = () => {
    /*------------------------------------PROPS--------------------------------*/
    /*------------------------------------STATE--------------------------------*/
    const [companyInfo, setCompanyInfo] = useState(defaultCompany)
    /*------------------------------------HOOKS--------------------------------*/
    const navigate = useNavigate()
    const { companyId } = useParams()

    /*------------------------------------FUNCTIONS--------------------------------*/
    const getCompanyInfo = async () => {
        try {
            const response = await axios.get(`/admin/companies/info/${companyId}`, { appSpinner: true })
            if (response.data.response) {
                setCompanyInfo(response.data.response)
            }
        } catch (error) {
            console.error('GET_COMPANY_INFO_ERROR')
        }
    }

    const openModuleInfo = (moduleData) => {
        const moduleTypePath = {
            'MODULE': adminRoutes.module_info.path,
            'TOKEN': adminRoutes.token_info.path
        }
        navigate(moduleTypePath[moduleData.moduleType], { state: { moduleData } })
    }

    /*------------------------------------EFFECT--------------------------------*/
    useEffect(() => {
        getCompanyInfo()
        // run effect once
        // eslint-disable-next-line
    }, [])

    /*------------------------------------RENDER--------------------------------*/

    return (
        <Flex gap='2rem' direction='column' className='admin_company_info_page'>
            <Text bold size='5' align='center' margin='1rem 0'>{companyInfo.businessName}</Text>
            <Grid gap='1rem' columns='1fr 1fr'>
                <Grid gap='2rem' contentY='start'>
                    <Link router bold underline size={4} href='billing'>Datos de facturación</Link>
                    <Flex>
                        <Text><b>Industria:</b> {companyInfo.industryType}</Text>
                    </Flex>
                    <Grid gap='1rem'>
                        <Text><b>Headcount:</b> {sumBranchesHeadCount(companyInfo.branches)}</Text>
                        {companyInfo.branches.map((branch, i) => (
                            <Text key={i}><b>{branch.name}:</b> {branch.headCount}</Text>
                        ))}
                    </Grid>
                    <Grid gap='1rem'>
                        <Text bold>Owners</Text>
                        <Grid gap='0.5rem' itemsX='start' itemsY='center'>
                            {companyInfo.owners.map((owner, i) => (
                                <Fragment key={i}>
                                    <Text>{owner.email}</Text>
                                </Fragment>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid gap='2rem' contentY='start'>
                    <Text bold>Módulos y artículos</Text>

                    {companyInfo.modules.map((module, i) => (
                        <Grid key={i} gap='1rem' padding='1rem' className='company_info__module_card'
                            onClick={() => { openModuleInfo(module) }}>
                            {module.moduleType === 'MODULE' ?
                                <Fragment>
                                    <Text bold align='center'>{module.name}</Text>
                                    <Flex>
                                        <Text><b>Plan:</b> {planOptions[module.plan]}</Text>
                                    </Flex>
                                    <Flex>
                                        <Text><b>Tarifa:</b> {module.fee}</Text>
                                    </Flex>
                                    <Flex>
                                        <Text><b>Saldo:</b> {global.parseCurrency(module.balance)}</Text>
                                    </Flex>
                                </Fragment>
                                :
                                <Fragment>
                                    <Text bold align='center'>{module.name}</Text>
                                    <Text><b>Tokens:</b> {module.tokens}</Text>
                                </Fragment>
                            }
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Flex>
    )
}

export default AdminCompanyInfo