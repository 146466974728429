import React, { useState } from 'react'
import axios from 'axios'

import { Button, Grid, Modal, Text, TextField } from 'components'

const RechargeBalance = (props) => {
    /*------------------------------------PROPS--------------------------------*/
    const { closeModal, moduleId } = props

    /*------------------------------------STATE--------------------------------*/
    const [balance, setBalance] = useState()
    // const [showCalculator, setShowCalculator] = useState()

    /*------------------------------------FUNCTION--------------------------------*/
    const generateInvoice = async () => {
        try {
            const response = await axios.post(`/admin/companies/modules/${moduleId}/income-invoice`,
                { amount: balance },
                { appSpinner: true, catchErrors: true }
            )
            if (response.data) {
                closeModal()
            }
        } catch (error) {
            console.error('GENERATE_INVOICE_ERROR')
        }
    }

    return (
        <Modal className='module_info__recharge_balance' maxWidth='25rem'>
            {/* {!showCalculator ? */}
            <Grid gap='2rem'>
                <Text bold align='center' size='5'>RECARGA DE SALDO</Text>
                <Grid gap='0.5rem' columns='1fr auto' itemsY='end'>
                    <TextField step='0.01' type='number' label='Monto de la recarga' value={balance} onChange={setBalance} />
                    {/* <Tooltip text='Calculadora'>
                            <IconButton icon='copy' onClick={() => setShowCalculator(true)} />
                        </Tooltip> */}
                </Grid>
                <Grid direction='column' gap='1rem' itemsX='center'>
                    <Button variant='outlined' onClick={closeModal}>Cancelar</Button>
                    <Button onClick={generateInvoice}>Generar factura</Button>
                </Grid>
            </Grid>
            {/* : */}
            {/* <Calculator setShowCalculator={setShowCalculator} setBalance={setBalance} /> */}
            {/* } */}
        </Modal>
    )
}

export default RechargeBalance
