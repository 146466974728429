import React, { createRef, forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import { useMessageBoxContext, useRenderInput, useValidateFields } from 'hooks'

import { Text } from 'components'

const FormHandler = (props, ref) => {
    /*--------------------------------------PROPS-----------------------------------*/
    const {
        fields,
        formData,
        setFormData,
    } = props

    /*--------------------------------------STATE-----------------------------------*/

    /*--------------------------------------REFS-----------------------------------*/
    const fieldsRef = useRef(Object.keys(fields).filter(key => !key.includes('label')).map(() => createRef()))

    /*--------------------------------------HOOKS-----------------------------------*/
    const renderInput = useRenderInput()
    const { showMBError } = useMessageBoxContext()
    const validateFields = useValidateFields()

    /*--------------------------------------FUNCTIONS-----------------------------------*/
    const handleChangeData = useCallback((name, value) => {
        setFormData(formData => ({ ...formData, [name]: value }))
    }, [setFormData])

    const setInputProps = (name) => {
        const {
            onChange,
        } = fields[name]

        const handleChangeField = value => {
            handleChangeData(name, value)
            onChange && onChange(value)
        }

        return ({
            onChange: handleChangeField,
            value: formData[name] || fields[name].inputProps?.defaultValue
        })
    }

    /*--------------------------------------IMPERATIVEHANDLE-----------------------------------*/
    const validateForm = useCallback(() => {
        const { valid, errors } = validateFields(fieldsRef)

        if (!valid)
            showMBError(errors)

        return valid
    }, [showMBError, validateFields])

    useImperativeHandle(ref, () => ({
        validate: validateForm,
        fieldsRef: fieldsRef.current
    }), [validateForm])

    /*--------------------------------------RENDER-----------------------------------*/
    let fieldCount = 0
    return Object.keys(fields).map((key, i) => key.includes('label') ?
        <Text key={key}>{fields[key]}</Text>
        :
        renderInput(fields[key].input, { key, ref: fieldsRef.current[fieldCount++], ...fields[key].inputProps, ...setInputProps(key) })
    )
}

export default forwardRef(FormHandler)