import { Button, CharacterField, Grid, Text } from 'components'
import React, { useCallback } from 'react'

import './da_section_access_code.scss'

const DASectionAccessCode = (props) => {
    /*------------------------------------PROPS--------------------------------*/
    const { section } = props

    /*------------------------------------STATE--------------------------------*/

    /*------------------------------------FUNCTIONS--------------------------------*/
    const handleClickEnter = useCallback((accessCode) => {
        console.log('Enter section => ', section)
        console.log('Code => ', accessCode)
    }, [section])

    const handleAccessCode = useCallback(v => {
        if (v.length < 6) return
        console.log(v)
        handleClickEnter(v.join(''))
    }, [handleClickEnter])

    /*------------------------------------RENDER--------------------------------*/
    return (
        <Grid className='da_section_access_code'>
            <Text bold size='4' align='center' color="third">Ingresa el código de <br/> acceso</Text>
            <CharacterField onChange={handleAccessCode} length={6} />
            <Button onClick={handleClickEnter}>Entrar</Button>
        </Grid>
    )
}

export default DASectionAccessCode
