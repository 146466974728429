
import React, {  useRef, createRef, memo} from 'react'

import { Grid } from 'components'
import SideMenuCategoriesNavigatorOption from './Option'
import Screens from './Screens'

export default memo ( function SideMenuCategoriesNavigator ({category, onChange}) {
    
    /*--------------------------------------STATE-------------------------------------------*/
        
        const options = Object.keys(Screens).map(k => Screens[k])//(0)
    
    /*---------------------------------------REF------------------------------------------*/
        
        const refOptions = useRef(options.map(() => createRef())) //refs a las opciones
        
    /*--------------------------------------EFFECT----------------------------------------*/  
        return (
            <Grid className={`side_menu__categories`} w100 contentY="start" columns='1fr' gap='1.34rem'>
                    {options.map((option, key) => 
                        <SideMenuCategoriesNavigatorOption 
                            {...{
                                text: option.text,
                                icon: option.icon,
                                key,
                                selected: key === category,
                                // onClick: useCallback(() => onChange(key), [onChange]),
                                onClick: () => {onChange(key)},
                                ref: refOptions.current[key], 
                            }}
                        />
                    )}
            </Grid> 
        )
})