import React, { useCallback, useEffect, useRef, useState,  } from 'react'
import axios from 'axios'
import { Button, Grid, Text, TextField, Toggle, CharacterField, MessageBox, Link, Divisor, Password, HelpButton } from 'components'
import { useSelector, useDispatch } from 'react-redux'
import { setInfoUser, setSettingsUser } from 'redux/reducers/auth/authSlice'
import regexDictionary from 'scripts/regexDictionary'
import './profile.scss'
import ChangePassword from '../ChangePassword/ChangePassword'

const DEFAULT_PROFILE_PHOTO = "https://magiei-resources.s3.us-east-2.amazonaws.com/Icons/icon-user-edit.svg"

const Profile = () => {
    const fileReference = useRef()
    const [showChangePassword, setShowChangePassword] = useState(false)
    const [savedPin, setSavedPin] = useState(false)
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false)
    const [changedSettingsInput, setChangedSettingsInput] = useState(false)
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [passwordWrong, setPasswordWrong] = useState(false)
    const messageBoxRef = useRef()
    const [accountSettings, setAccountSettings] = useState({ sessionDuration: 8, blockingTime: '', pin: '', theme: false, modePinActivated: false })
    const { auth } = useSelector(store => store)
    const dispatch = useDispatch()

    const changeUserPhoto = useCallback((event) => {
        console.log(event.target.files[0].size)
        const ALLOWED_FILE_LIMIT_SIZE = 2621440; //2.5MB
        const { size } = event.target.files[0]

        if( ALLOWED_FILE_LIMIT_SIZE >= size ){
            const options = {
                url: '/user/account/photo',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                data: {
                    formData: { file: event.target.files[0] },
                },
                appSpinner: true
            }
            axios(options)
            .then(response => {
                fileReference.current.value = ""
                dispatch(setInfoUser({ photoUrl: response.data.photoUrl }))
            })
            .catch(err => {
                console.log(err)
            })
        }else{
            messageBoxRef.current.show({
                content: <Text medium align="center">La foto es demasiado grande, selecciona una con un tamaño menor a 2 Mb.</Text>,
                buttons: <Button onClick={() => { messageBoxRef.current.hide() }}>Entendido</Button>
            })
        }
    }, [dispatch])

    const handleChangeSettings = useCallback((value, name) => {
        setAccountSettings(accountSettings => ({ ...accountSettings, [name]: value }))
        if (name === 'blockingTime') setChangedSettingsInput(true)
    }, [])

    const handleAccessCode = useCallback(v => {
        if (v.length === 4) {
            setAccountSettings(accountSettings => ({ ...accountSettings, pin: v.join('') }))
            setChangedSettingsInput(true);
        }else{
            setAccountSettings(accountSettings => ({ ...accountSettings, pin: '' })) 
        }
    }, [])

    const handleSaveSettings = useCallback((value, name) => {
        if (value !== '' && value !== '0') {
            const options = {
                url: `/user/account/setting/${name}`,
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                data: {
                    value
                },
                appSpinner: true
            }
            axios(options)
                .then(response => {
                    if (response.data.token) global.setAxiosAuthToken(response.data.token)
                    if (response.data) dispatch(setSettingsUser(response.data))

                })
                .catch(err => {
                    console.log(err)
                })
        } else {
            messageBoxRef.current.show({
                content: <Text medium align="center">No se permiten valores vacios o menores a 1</Text>,
                buttons: <Button onClick={() => { messageBoxRef.current.hide() }}>Entendido</Button>
            })
        }
    }, [dispatch])

    const handleSavePinModeIfSettingsArePresent = useCallback((value) => {
        if(value && accountSettings.blockingTime && accountSettings.pin)
            handleSaveSettings(value, 'modePinActivated')
    }, [handleSaveSettings, accountSettings])


    const handleChangeModePin = useCallback((value) => {
        handleChangeSettings(value, 'modePinActivated')
        if(!value) handleSaveSettings(value, 'modePinActivated')

        handleSavePinModeIfSettingsArePresent(value)
    }, [handleChangeSettings, handleSaveSettings, handleSavePinModeIfSettingsArePresent])

    const showMDConfirmPass = useCallback(() => {
        setShowPasswordConfirmation(true)
        setPasswordConfirm('')
        setPasswordWrong(false)
    }, [])

    const savePinSettings = () => {
        if (accountSettings.pin && accountSettings.blockingTime) {
            const options = {
                url: `/user/account/setting/blocking-mode`,
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                data: {
                    pin: accountSettings.pin,
                    modePinActivated: accountSettings.modePinActivated,
                    blockingTime: accountSettings.blockingTime
                },
                appSpinner: true
            }
            axios(options)
                .then(response => {
                    dispatch(setSettingsUser(response.data))
                    setSavedPin(true)
                    setChangedSettingsInput(false)
                })
                .catch(err => {
                    console.log(err)
                })
        } else {
            messageBoxRef.current.show({
                content: <Text medium align="center">Hay campos vacios o menores a 1</Text>,
                buttons: <Button onClick={() => { messageBoxRef.current.hide() }}>Entendido</Button>
            })
        }
    }

    const checkPasswordValid = async e => {
        e.preventDefault()
        const options = {
            url: `/user/account/confirm-password`,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            data: {
                password: passwordConfirm
            },
            appSpinner: true
        }
        axios(options)
            .then(() => {
                setAccountSettings(accountSettings => ({ ...accountSettings, 'pin': '' }))
                setShowPasswordConfirmation(false)
                setSavedPin(false)
            })
            .catch(() => {
                setPasswordWrong(true)
            })
    }

    useEffect(() => {
        if (auth.user !== null) setAccountSettings(auth.user.settingsUser)
        if (auth.user.settingsUser.pin !== '') setSavedPin(true)
    }, [auth.user])


    //GUI FUNCTION
    const displayProfileDataLayout = (label, key) =>{
        if(!auth.user?.infoUser[key]) return
        let value = auth.user?.infoUser[key]

        if ( Array.isArray( value )) value = value.join(" , ")

        return <>
            <Text regular color="second">{label}</Text>
            <Text medium color="third">{value}</Text>
        </>
    }

    const imageClassName = auth.user !== null ? ((auth.user?.infoUser?.photoUrl === DEFAULT_PROFILE_PHOTO || auth.user?.infoUser?.photoUrl === "") ? "user_info__default" : "account__user_photo") : "account__user_photo"
    return (
        <Grid className="account" itemsX="center" contentY="start" gap="0.7em" padding="1.14em 0.42em">
            <MessageBox ref={messageBoxRef} />
            {showPasswordConfirmation &&
                <Grid className="password_confirm_mask">
                    <Grid className="password_confirm_mask__content" padding="2.85em 3.14em" gap="1.71em">
                        <form onSubmit={checkPasswordValid}>
                            <Grid>
                                <Grid gap="0.71rem" itemsX="center">
                                    <Text medium size="4">Escribe tu contraseña para autorizar cambio de PIN</Text>
                                    <Password
                                        value={passwordConfirm}
                                        onChange={setPasswordConfirm}
                                        maxWidth="20em"
                                        w100
                                    />
                                    {passwordWrong && <Text color="error">La contraseña es incorrecta</Text>}
                                </Grid>
                                <Grid style={{ marginTop: '1.71rem' }} gap="1.71em" contentX="center">
                                    <Button type="submit">Confirmar</Button>
                                    <Button variant="outlined" onClick={() => { setShowPasswordConfirmation(false) }}>Cancelar</Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            }
            <Grid w100 gap="1.714em" contentY="start" itemsX="center" padding="1.14rem 4.57rem 1.71rem 4.57rem" className="account__user_info">
                <Text medium size="8" color="third">Mi Cuenta</Text>
                <Grid w100 itemsX="center" gap="1.142rem">
                    <Grid className="account__user_info__photo">
                        <input
                            
                            className='account__user_input_photo'
                            type="file"
                            accept="image/*"
                            ref={fileReference}
                            name="myImage"
                            onChange={changeUserPhoto}
                            title=""
                        />
                        {auth.user?.infoUser?.photoUrl === "" ?
                            <Grid className="user_info__default">
                                <Text bold color="second" align="center">Agrega o cambia tu foto</Text>
                            </Grid>
                            :
                            <Grid>
                                <Grid className="user_info__change_photo">
                                    <Text bold color="second" align="center">Agrega o cambia tu foto</Text>
                                </Grid>
                                <img alt="" src={auth.user?.infoUser?.photoUrl || DEFAULT_PROFILE_PHOTO} className={imageClassName} />
                            </Grid>
                        }
                    </Grid>
                    <Grid w100 maxWidth="18.642rem" columns="auto 1fr" gap="1em 1.214em">
                        {displayProfileDataLayout("Nombre", 'name')}
                        {displayProfileDataLayout("Email", 'email')}
                        {displayProfileDataLayout("Permiso", 'profileName')}
                        {displayProfileDataLayout("Cédula", 'profesionalLicense')}
                        {displayProfileDataLayout("Sucursal", 'branches')}
                    </Grid>
                </Grid>

                {!showChangePassword &&
                    <Link style={{margin: '0.357rem 0rem'}} underline bold className="change_password"
                        onClick={() => { setShowChangePassword(true) }} >
                        Cambiar contraseña
                    </Link>}
                {showChangePassword &&
                    <Grid w100 className="change_password__container">
                        <ChangePassword setShowChangePassword={setShowChangePassword} />
                    </Grid>
                }

                <Divisor maxWidth="42.857em" />

                <Grid gap="1.6em">
                    <Grid gap="0.3rem">
                        <Grid itemsY="center" contentX="start" columns="auto auto" gap="0.3rem">
                            <Text bold color="third">Duración de la sesión activa</Text>
                            <HelpButton size="1.142" position="right" helpBubbleMaxWidth="16.85em">Después de este tiempo se cerrará la sesión y deberás ingresar de nuevo con tus credenciales.</HelpButton>
                        </Grid>
                        <Grid columns="auto 1fr" itemsY="center" gap="0.7em">
                            <TextField
                                type="number"
                                onChange={v => handleChangeSettings(v, 'sessionDuration')}
                                maxWidth="5.1em"
                                value={accountSettings.sessionDuration}
                                onBlur={() => handleSaveSettings(accountSettings.sessionDuration, 'sessionDuration')}
                            />
                            <Text color="second">Hora(s)</Text>
                        </Grid>
                    </Grid>

                    <Grid gap="0.9rem" itemsX="left">
                        <Grid columns="auto auto" gap="0.714rem" itemsY="center">
                            <Grid columns="auto auto" itemsY="center" gap="0.3rem">
                                <Text bold>Habilitar PIN de 4 digitos</Text>
                                <HelpButton size="1.142" position="right" helpBubbleMaxWidth="16.85em">Durante una sesión activa, la pantalla se bloqueará y deberás ingresar el PIN para desbloquearla.</HelpButton>
                            </Grid>
                            <Toggle
                                value={accountSettings.modePinActivated}
                                onChange={handleChangeModePin}
                            />
                        </Grid>
                        {accountSettings.modePinActivated &&
                            <Grid gap="1.6em" itemsX="left">
                                <Grid columns="auto auto" gap="1em" itemsY="center">
                                    <TextField
                                        type="number"
                                        label="Tiempo de bloqueo"
                                        value={accountSettings.blockingTime}
                                        onChange={v => handleChangeSettings(v, 'blockingTime')}
                                        maxWidth="11.571rem"
                                    />
                                    <Text regular color='second' style={{ marginTop: '0.8em' }}>Minutos</Text>
                                </Grid>
                                {savedPin ?
                                    <Link bold underline className="change_pin__text" onClick={showMDConfirmPass}>
                                        Cambiar PIN
                                    </Link>
                                    :
                                    <Grid gap="0.6em" contentX="left">
                                        <Text size="2">Digita tu PIN</Text>
                                        <CharacterField type="password" onChange={handleAccessCode} length={4} pattern={regexDictionary.onlyIntegerNumbers} />
                                    </Grid>
                                }
                                {
                                   changedSettingsInput && <Grid direction="column" gap="1rem"><Button variant="outlined" onClick={() => {setChangedSettingsInput(false)}}>Cancelar</Button><Button onClick={savePinSettings}>Guardar</Button></Grid>
                                }
                                
                            </Grid>
                        }
                    </Grid>

                </Grid>

            </Grid>
        </Grid>
    )
}

export default Profile
