import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import authService from './authService'

const user = JSON.parse(localStorage.getItem('user'))
const company = JSON.parse(localStorage.getItem('company'))

const userInitialState = {
    infoUser: {
        name: '',
        email: '',
        profileName: '',
        profesionalLicense: '',
        branchName: '',
        photoUrl: '',
    },
    settingsUser: {
        sessionDuration: 8,
        blockingTime: '',
        pin: '',
        darkMode: false,
        modePinActivated: false
    }
}

const adminUserInitialState = {
    infoUser: {
        name: '',
        email: '',
    },
    settingsUser: {
        sessionDuration: 8,
        blockingTime: '',
        pin: '',
        darkMode: false,
        modePinActivated: false
    }
}

const companyInitialState = {
    companyLogoUrl: '',
    companyIconUrl: '',
    razonSocial: '',
    rfc: '',
    industryType: '',
    legalRepresentative: '',
    predominantActivity: '',
}

const initialState = {
    user: { ...userInitialState, ...(user || {}) },
    company: { ...companyInitialState, ...(company || {}) },
    authenticated: Boolean(user),
    isLoading: false,
    message: '',
}

export const signup = createAsyncThunk('/company/create/account', async (userData, thunkAPI) => {
    try {
        return await authService.signup(userData)
    } catch (error) {
        const errorMessage = (error.response && error.response.data && error.response.data.message)
            || error.message || error.toString()

        return thunkAPI.rejectWithValue(errorMessage)
    }
})

export const login = createAsyncThunk('/user/login', async (userData, thunkAPI) => {
    try {
        return await authService.login(userData)
    } catch (error) {
        const errorMessage = (error.response && error.response.data && error.response.data.message)
            || error.message || error.toString()

        return thunkAPI.rejectWithValue(errorMessage)
    }
})

export const adminLogin = createAsyncThunk('/admin/session/login', async (userData, thunkAPI) => {
    try {
        return await authService.adminLogin(userData)
    } catch (error) {
        const errorMessage = (error.response && error.response.data && error.response.data.message)
            || error.message || error.toString()

        return thunkAPI.rejectWithValue(errorMessage)
    }
})

export const logout = createAsyncThunk('/user/logout', async (_, thunkAPI) => {
    try {
        return await authService.logout()
    } catch (error) {
        const errorMessage = (error.response && error.response.data && error.response.data.message)
            || error.message || error.toString()

        return thunkAPI.rejectWithValue(errorMessage)
    }
})

export const fetchCompanyData = createAsyncThunk('/company/account', async (_, thunkAPI) => {
    try {
        return await authService.fetchCompanyData()
    } catch (error) {
        const errorMessage = (error.response && error.response.data && error.response.data.message)
            || error.message || error.toString()

        return thunkAPI.rejectWithValue(errorMessage)
    }
})

const authSlice = createSlice({
    name: 'counter',
    initialState,
    reducers: {
        resetAuth: state => {
            state.user = userInitialState
            state.company = companyInitialState
            state.authenticated = false
            state.isLoading = false
            state.message = ''
            global.clearSession()
        },
        setUser: (state, action) => {
            state.user = { ...state.user, ...action.payload }
        },
        setInfoUser: (state, action) => {
            state.user.infoUser = { ...state.user.infoUser, ...action.payload }
            localStorage.setItem('user', JSON.stringify(state.user))
        },
        setSettingsUser: (state, action) => {
            delete action.payload.token
            state.user.settingsUser = { ...state.user.settingsUser, ...action.payload }
            localStorage.setItem('user', JSON.stringify(state.user))
        },
        setCompany: (state, action) => {
            state.company = { ...state.company, ...action.payload }
            localStorage.setItem('company', JSON.stringify(state.company))
        },
    },
    extraReducers: (builder) => {
        builder
            //company data
            .addCase(fetchCompanyData.fulfilled, (state, action) => {
                state.message = action.payload?.error
                state.company = action.payload.error ? companyInitialState : action.payload
            })
            .addCase(fetchCompanyData.rejected, (state, action) => {
                state.company = companyInitialState
                state.message = action.payload
            })
            // SIGN UP
            .addCase(signup.pending, state => {
                state.isLoading = true
            })
            .addCase(signup.fulfilled, (state, action) => {
                state.isLoading = false
                state.authenticated = !action.payload.error
                state.message = action.payload.error ? action.payload.error : ''
                state.user = action.payload.error ? userInitialState : action.payload.user
            })
            .addCase(signup.rejected, (state, action) => {
                state.user = userInitialState
                state.authenticated = false
                state.isLoading = false
                state.message = action.payload
            })
            .addCase(login.pending, state => {
                state.isLoading = true
            })
            .addCase(login.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.authenticated = !payload.error
                state.message = payload.error ? payload.error : ''
                state.user = payload.error ? userInitialState : payload.user
                document.body.classList.toggle('dark', payload.user.settingsUser.darkMode)
            })
            .addCase(login.rejected, (state, action) => {
                state.user = userInitialState
                state.authenticated = false
                state.isLoading = false
                state.message = action.payload
                global.clearSession()
            })
            .addCase(adminLogin.pending, state => {
                state.isLoading = true
            })
            .addCase(adminLogin.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.authenticated = !payload.error
                state.message = payload.error ? payload.error : ''
                state.user = payload.error ? adminUserInitialState : { ...payload.user, settingsUser: adminUserInitialState.settingsUser }
                // document.body.classList.toggle('dark', payload.user.settingsUser.darkMode)
            })
            .addCase(adminLogin.rejected, (state, action) => {
                state.user = userInitialState
                state.authenticated = false
                state.isLoading = false
                state.message = action.payload
                global.clearSession()
            })
            .addCase(logout.pending, state => {
                state.isLoading = true
            })
            .addCase(logout.fulfilled, (state) => {
                state.user = userInitialState
                state.company = companyInitialState
                state.authenticated = false
                state.isLoading = false
                state.message = ''
                global.clearSession()
            })
            .addCase(logout.rejected, (state, action) => {
                state.user = userInitialState
                state.company = companyInitialState
                state.authenticated = false
                state.isLoading = false
                state.message = ''
                global.clearSession()
            })

    }
})

export const { resetAuth, setUser, setInfoUser, setSettingsUser, setAccountStatus, setCompany } = authSlice.actions

export default authSlice.reducer