import React, { useCallback, useEffect, useState } from 'react'
import { Grid, Slider, LottieAnimationPlayer, Icon, Text } from 'components'
import { Login, RecoverPassword, Signup } from "Pages"
import { useParams } from 'react-router-dom'
import DirectAccessMenu from './DirectAccessMenu/DirectAccessMenu'

import './home_no_session.scss'

const SessionPage = () => {
    /*---------------------------------------STATE-----------------------------------------*/
    const [currentScreen, setCurrentScreen] = useState(0)

    /*---------------------------------------HOOKS-----------------------------------------*/
    const { section } = useParams()

    /*---------------------------------------FUNCTIONS-----------------------------------------*/
    const handleCurrentScreen = useCallback((screen) => {
        setCurrentScreen(screen)
    }, [])

    /*---------------------------------------EFFECTS-----------------------------------------*/
    useEffect(() => {
        setCurrentScreen(
            ({ 'login': 0, 'register': 1, 'recover_password': 2 })[section]
        )
    }, [section])

    /*---------------------------------------RENDER-----------------------------------------*/
    return (
        <Grid selfY="center" rows="auto 1fr">
            <Slider currentScreen={currentScreen}>
                <Login handleCurrentScreen={handleCurrentScreen} />
                <Signup handleCurrentScreen={handleCurrentScreen} />
                <RecoverPassword handleCurrentScreen={handleCurrentScreen} />
            </Slider>
        </Grid>
    )
}

const HomeNoSession = () => {

    const [showAccessMenuMovil, setShowAccessMenuMovil] = useState(false)

    return (
        <Grid className="home_no_session" gap="1.7em">
            <Grid w100 gap="0.7em" className="logo_container">
                <Grid gap="0.6em" itemsY="center" columns="auto 1fr" className="direct_access__button" onClick={() => {setShowAccessMenuMovil(true)}}>
                    <Text bold align='left'>Accesos Directos</Text>
                    <Icon icon='arrow' direction='top' size={1.5} filter="fourth" />
                </Grid>
                <Grid maxWidth="24em">
                    <LottieAnimationPlayer autoplay loop animation="logo-magiei-animated" />
                </Grid>
                {/* <img alt="logo" src="https://magiei-resources.s3.us-east-2.amazonaws.com/Imagenes/MagieiLogoFull.png" /> */}
            </Grid>
            <SessionPage />
            <DirectAccessMenu showAccessMenuMovil={showAccessMenuMovil} setShowAccessMenuMovil={setShowAccessMenuMovil} />
        </Grid>
    )
}

export default HomeNoSession
